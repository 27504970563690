import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function AccordionExpandIcon() {
  const card = [
    {
      head: " What services does Eazymov offer?",
      text: "Eazymov offers a wide range of transportation and logistics services, including Ocean Network services, air freight, pet relocation, vehicle transportation, and door-to-door delivery. We specialize in providing customized solutions to meet the unique needs of each customer.",
    },
    {
      head: "How do I book a service with Eazymov?",
      text: "Booking a service with Eazymov is simple. You can either visit our website and fill out the booking form, or contact our customer service team directly by phone or email. Once we have your details, we will provide you with a tailored quote and guide you through the booking process.",
    },
    {
      head: "Can I track my shipment?",
      text: "Yes, Eazymov provides real-time tracking for all shipments. Once your shipment is on its way, you will receive a tracking number that allows you to monitor its progress online. Our tracking system is easy to use and provides up-to-date information on the location and status of your shipment.",
    },
    {
      head: "What are the payment options available?",
      text: "Eazymov offers a variety of payment options to suit your needs. You can pay via credit/debit card, bank transfer, or through our secure online payment portal. We also offer flexible payment plans for certain services. Please contact our customer service team for more information on payment options.",
    },
    {
      head: "How does Eazymov handle customs clearance?",
      text: "Eazymov has a team of experienced professionals who manage customs clearance on your behalf. We handle all the necessary paperwork and ensure that your goods comply with the customs regulations of the destination country. Our goal is to make the customs process as smooth and hassle-free as possible.",
    },
    {
      head: "What documents are required for pet relocation?",
      text: "For pet relocation, you will need to provide various documents, including your pet’s health certificate, vaccination records, and any required import/export permits. The specific documents required may vary depending on the destination country. Our pet relocation experts will guide you through the documentation process and ensure everything is in order.",
    },
    {
      head: "What if there is a delay in my shipment?",
      text: "While Eazymov strives to ensure timely delivery, unforeseen circumstances such as weather conditions or customs delays may affect the shipment schedule. In the event of a delay, our team will notify you immediately and provide regular updates until your shipment arrives.",
    },
    {
      head: "Does Eazymov offer insurance for shipments?",
      text: "Yes, Eazymov offers comprehensive insurance options to protect your goods during transit. Our insurance covers a wide range of scenarios, including damage, loss, and theft. You can choose the insurance coverage that best suits your needs when booking your service.",
    },
    {
      head: "Can I cancel or modify my booking?",
      text: "es, you can cancel or modify your booking. Please note that cancellation or modification policies may vary depending on the service and timing. We recommend contacting our customer service team as soon as possible if you need to make changes to your booking.",
    },
    {
      head: "How can I contact Eazymov for further assistance?",
      text: "You can contact Eazymov through our customer service hotline, email, or the contact form on our website. Our customer service team is available to assist you with any questions or concerns you may have. ",
    },
  ];
  return (
    <div className="container">
      <div className="row my-2">
        <h1
          style={{
            fontSize: "36px",
            fontWeight: "700",
            textShadow: "2px 2px 5px gray",
          }}
        >
          FAQ's
        </h1>
      </div>
      {card.map((e) => (
        <>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <h5>{e.head}</h5>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{e.text}</Typography>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
    </div>
  );
}
