import React from "react";
import Layout from "../comp/Layout";
import bg from "../img/about-page-bg.jpg";
import { Link } from "react-router-dom";

const PrivacyTerms = () => {
  return (
    <Layout>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              PRIVACY & TERMS
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"} Privacy & Terms
            </p>
          </div>
        </div>
      </div>
      <div className="container py-4">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="hp">Privacy Policy</h1>
            <b>
              Hum apne customers ko hamesha yeh salah dete hain ki service lene
              se pehle packers and movers company ki puri jaankari lein. Eazymov
              kisi bhi dhokadhadi ka samarthan nahi karta. Aap apne saaman ko
              kisi bhi company ke hawale karne se pehle nimn baaton ka dhyan
              rakh sakte hain:
            </b>
            <ol className="ol">
              <li>
                <b>Company ke bataye hue address par actual mein hai ya nahi</b>{" "}
                : Iska pata lagane ke liye aap us address par personally visit
                kar sakte hain ya online verification kar sakte hain.
              </li>
              <li>
                <b>Quotation mein hidden cost</b> : Yeh confirm kar lein ki
                quotation mein koi chhupi hui cost to nahi hai. Sab kuch saf saf
                likhit mein lein.
              </li>
              <li>
                <b>Company kitne purani hai aur uska experience</b> : Company
                kitne saalon se kaam kar rahi hai aur uska industry mein kya
                reputation hai, yeh jaana zaroori hai.
              </li>
              <li>
                <b>Reference mangna</b> : Aap unke pehle ke customers ka
                reference bhi maang sakte hain taaki unki services ka feedback
                mil sake.
              </li>
            </ol>
            <ul>
              <b>
                * Eazymov kisi bhi packers and movers company ka address
                verification nahi karta. Haan, humare paas unse jude kuch
                documents ho sakte hain jaise:
              </b>
              <li>
                <b>Owner ka Aadhar Card</b>
              </li>
              <li>
                <b>Owner ka PAN Card</b>
              </li>
              <li>
                <b>GST Number</b>
              </li>
              <li>
                <b>Owner ki bank details (cheque)</b>
              </li>
              <li>
                <b>Owner ka photo</b>
              </li>
              <li>
                <b>Contact number</b>
              </li>
              <li>
                <b>Email ID</b>
              </li>
              In documents ko aap verify karne ke liye bhi use kar sakte hain,
              lekin aap apne taraf se bhi sabhi jaankariyon ka verification
              zaroor kar lein.
            </ul>
            <ul>
              <b>
                Aapko aur bhi cheezein dhyaan mein rakhni chahiye jab aap
                packers and movers se service le rahe hain:
              </b>
              <li>
                <b>Insurance</b> : Apne saaman ke liye insurance lena na
                bhoolein. Transit insurance lena zaroori hai taaki koi bhi
                nuksan hone par aapko pura insurance claim mil sake. Eazymov bhi
                apne services mein insurance ko compulsory banata hai.
              </li>
              <li>
                <b>Terms and Conditions</b> : Company ke terms and conditions ko
                dhyan se padhein, khaas kar payment terms, cancellation
                policies, aur delivery timeline. Yeh zaroor dekh lein ki
                delivery time realistic hai ya nahi.
              </li>
              <li>
                <b>Online Reviews</b> : Online reviews padhein, Google Reviews
                ya social media platforms par customers ke feedback dekh sakte
                hain. Agar zyada negative reviews hain, toh aapko alert ho jana
                chahiye.
              </li>
              <li>
                <b>Documentation</b> : Hamesha sabhi communications aur
                agreement ko likhit mein rakhein. Verbal assurance par dependent
                na rahein. Delivery receipt aur packing list ko sambhal kar
                rakhein.
              </li>
              <li>
                <b>Customer Support</b> : Yeh check kar lein ki company ka
                customer support efficient hai ya nahi. Koi problem hone par kya
                unka response time thik hai?
              </li>
              <li>
                <b>Safety Measures</b> : Samay ke dauran COVID ya doosri safety
                measures ka bhi dhyan rakhna zaroori hai. Yeh ensure karein ki
                workers safety protocols follow kar rahe hain.
              </li>
              In sab cheezon ko dhyan mein rakhne se aap apni shifting
              experience ko kaafi had tak sukhad bana sakte hain aur kisi bhi
              tarah ki dhokadhadi se bach sakte hain.
            </ul>
            <ul>
              <b>
                Yeh kuch aur baatein hain jo aapko packers and movers service
                lete waqt dhyan mein rakhni chahiye:
              </b>
              <li>
                <b>Payment Terms</b> : Pehle se hi yeh clear kar lein ki payment
                kaise hoga. Kuch companies puri payment advance mein maangti
                hain, jabki kuch installment mein bhi allow karti hain. Hamesha
                written invoice lein aur payment ke baad receipt lena na
                bhoolein.
              </li>
              <li>
                <b>Packaging Material</b> : Yeh confirm karein ki jo packaging
                material use kiya ja raha hai, wo high quality ka ho, taaki
                aapka saaman safely destination tak pahunch sake. Fragile items
                ke liye extra precautions lene ko kahe.
              </li>
              <li>
                <b>Inventory List</b> : Packers and movers ke saath ek detailed
                inventory list banayein jisme saari items ka mention ho. Isse
                aapko pata rahega ki aapka saaman safe aur complete hai
              </li>
              <li>
                <b>Claims Process</b> : Agar koi nuksan hota hai, toh uske liye
                claim ka process kya hoga? Yeh pehle se hi jaan lein. Aapko kis
                kis document ki zaroorat hogi, aur kitne samay mein claim settle
                hoga, yeh sab clear hona chahiye.
              </li>
              <li>
                <b>Contract Details</b> :Jo bhi contract sign kar rahe hain,
                uska ek copy apne paas rakhein. Contract mein sab kuch clearly
                mentioned hona chahiye, jaise delivery date, packing aur
                unpacking details, aur additional services agar aap le rahe hain
                toh.
              </li>
              <li>
                <b>Communication</b> : Aapke aur packers and movers ke beech
                communication clear aur continuous hona chahiye. Unke
                representative ka number aapke paas ho aur aapka number unke
                paas, taaki koi bhi emergency mein turant contact kiya ja sake.
              </li>
              <li>
                <b>After-Service Support</b> : Kuch companies after-service
                support bhi deti hain, jaise unpacking aur setup mein madad.
                Agar yeh service available ho, toh iske baare mein pehle se hi
                discuss kar lein.
              </li>
            </ul>
            <h1 className="hp">Terms of Use</h1>
            <ol className="ol">
              <li>
                Eazymov advised You to kindly check the genuineness of all
                companies listed above on your own behalf prior to signing a
                contract with them. We are in no way responsible for any loss.
              </li>
              <li>
                Service Agreement: By using Eazymov's services, users agree to
                the terms outlined. This includes the scope of services, payment
                terms, and any additional conditions specific to the type of
                moving service selected.
              </li>
              <li>
                Payment and Fees: Clear details regarding moving fees, payment
                methods, and any additional charges are outlined in the terms.
                Users are expected to adhere to the specified payment timelines.
              </li>
              <li>
                Cancellation and Rescheduling: Eazymov's terms include policies
                related to cancellation and rescheduling of moving services.
                Users are provided with guidelines on how to proceed in such
                situations.
              </li>
              <li>
                Liability and Insurance: The terms detail the extent of
                liability in case of damage or loss during the moving process.
                Information about transit insurance coverage is also provided.
              </li>
              <li>
                User Responsibilities: Users are expected to provide accurate
                information, cooperate during the moving process, and adhere to
                guidelines provided by Eazymov. Failure to do so may impact the
                provision of services.
              </li>
              <li>
                Dispute Resolution: Procedures for dispute resolution, including
                contact information for dispute resolution mechanisms, are
                outlined in the terms.
              </li>
              <li>
                Termination of Services: Circumstances under which Eazymov may
                terminate services are detailed in the terms. This includes
                breaches of terms by the user.
              </li>
              <li>
                Governing Law: The terms specify the governing law under which
                any legal disputes or concerns will be addressed.
              </li>
              <li>
                Conclusion: Eazymov provides transporters, packers & and movers
                for customers otherwise no other role of Eazymov between the
                customer & and the transporter, if customers need any
                information about the transport company, Eazymov has the
                information (Registered address, Aadhar Card, PAN card and GST
                certificates) there is no other information about transporter,
                packers & movers has Eazymov.
              </li>
            </ol>
            <p className="ol">
              {" "}
              Understanding and agreeing to these Privacy & Terms is crucial for
              users engaging with Eazymov's Packers & Movers services. It
              ensures a transparent and mutually beneficial relationship between
              the service provider and the customer.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyTerms;
