import React, { useState, useEffect } from "react";
import AddCollege from "./addCollege";

import logo from "../../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [auth, setAuth] = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(true);

  // const [isloading, setIsLoading] = useState/
  const [showSideNav2, setShowSideNav2] = useState(false); // State to control SideNav2 visibility

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" className="dashlogo" />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>{auth?.user?.name}</div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem
                  onClick={() => {
                    navigate("/dashboard/admin/profile");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPerson}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Profile
                </MenuItem> */}
                <MenuItem onClick={handleClose}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {drawerOpen && !showSideNav2 ? <SideNav /> : <SideNav2 />}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerOpen ? 250 : 50}px)`,
          transition: "width 0.3s",
        }}
      >
        <Toolbar />

        <Box mt={3}>
          {" "}
          <AddCollege />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
