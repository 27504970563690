import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/biketransport.jpg";
import "./service.css";
import FirstComp from "../comp/slide";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import LinaerStepper from "../comp/Counter";

const BikeTransport = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Bike-Transportation"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              Bike Transportation
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Bike Transportation
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Bike Transportation</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, our Bike Transportation is designed to provide
              efficient and reliable transportation solutions for your cargo.
              With a vast network of well-established routes and strategic
              partnerships, we ensure that your goods are transported safely and
              on time. Our state-of-the-art tracking technology keeps you
              informed throughout the journey, offering peace of mind and
              transparency.
            </p>
            <p>
              Our Bike Transportation offers comprehensive coverage across a
              wide range of regions, ensuring seamless delivery from major urban
              centers to remote locations. Whether you need to transport goods
              across the country or within regional boundaries, Eazymov is
              equipped to handle diverse logistics challenges with precision and
              efficiency.
            </p>
            <p>
              Eazymov is committed to excellence and innovation in our overland
              network services. We continuously invest in modern fleet
              management and infrastructure to enhance the efficiency and
              sustainability of our operations. By prioritizing customer
              satisfaction and adapting to industry advancements, we strive to
              set new standards in overland transportation.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Bike Transportation Services
            </h1>
            <h2 className="htag mt-5">Introduction to Bike Transportation</h2>
            <p className="ptag">
              At Eazymov, we understand that your bike is more than just a mode
              of transportation—it's a prized possession. Our bike
              transportation services are designed to ensure that your bike is
              relocated with the utmost care and safety. Whether you're moving
              to a new city or simply need to transport your bike across the
              country, we've got you covered.
            </p>
            <h2 className="htag mt-5">
              Why Choose Eazymov for Bike Transportation?
            </h2>
            <p className="ptag">
              With years of experience in the logistics industry, Eazymov has
              perfected the art of bike transportation. We use specially
              designed carriers and secure packing materials to protect your
              bike from any potential damage during transit. Our trained
              professionals handle every step of the process, from pick-up to
              delivery, with precision and care.
            </p>
            <h2 className="htag mt-5">Safe and Secure Bike Transportation</h2>
            <p className="ptag">
              Safety is our top priority when it comes to transporting your
              bike. Eazymov employs state-of-the-art technology and equipment to
              ensure that your bike is securely fastened and protected
              throughout the journey. Our GPS-enabled tracking system allows you
              to monitor your bike's location in real time, giving you peace of
              mind.
            </p>
            <h2 className="htag mt-5">Door-to-Door Service</h2>
            <p className="ptag">
              We offer convenient door-to-door bike transportation services, so
              you don't have to worry about dropping off or picking up your bike
              from a distant location. Our team will pick up your bike from your
              doorstep and deliver it to your desired location, ensuring a
              hassle-free experience.
            </p>
            <h2 className="htag mt-5">Affordable Pricing</h2>
            <p className="ptag">
              At Eazymov, we believe that quality service shouldn't come at an
              exorbitant price. Our bike transportation services are
              competitively priced, offering you the best value for your money.
              We provide transparent pricing with no hidden charges, ensuring
              that you know exactly what you're paying for.
            </p>
            <h2 className="htag mt-5">Customer Satisfaction Guaranteed</h2>
            <p className="ptag">
              Customer satisfaction is at the core of our services. Our
              dedicated support team is available 24/7 to assist you with any
              queries or concerns. With Eazymov, you can rest assured that your
              bike transportation experience will be smooth, efficient, and
              worry-free.
            </p>
          </div>
        </div>
      </div>

      <FirstComp />
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BikeTransport;
