import React, { useState } from "react";
import Layout from "../comp/Layout";
import bg from "../img/about-page-bg.jpg";
import { Link } from "react-router-dom";
import "./contact.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [service, setService] = useState("");
  // const [response, setResponse] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://eazymov.in:8092/api/contact/newContact",
        {
          name,
          email,
          phone,
          message,
          service,
        }
      );

      if (res?.data?.success) {
        toast.success("we will contact you as soon as possible");
        setTimeout(function () {
          // Reload the current page
          window.location.reload();
        }, 5000);
      } else {
        toast.error("something went wrng");
      }
    } catch (error) {
      toast.error(error + "something went wrong");
    }
  };

  return (
    <Layout title={"Contact-Us"}>
      <ToastContainer />
      <div style={{ overflow: "hidden" }}>
        <div
          className="container-fluid p-5"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="row text-light">
            <div className="col-sm-12">
              <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
                CONTACT US
              </h4>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  backgroundColor: "white",
                  border: "none",
                  margin: "20px 0",
                }}
              ></div>
              <p style={{ fontSize: "16px", padding: "20px 0" }}>
                You Are Here : <Link to="/">Home</Link> {">"} Contact Us
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid p-5">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div
                className="p-3"
                style={{ backgroundColor: "#0B60A9", color: "white" }}
              >
                <h6
                  style={{ fontSize: "18px", fontWeight: "700" }}
                  className="py-3"
                >
                  Our Office Address
                </h6>
                <span style={{ fontSize: "14px" }}>Phone: +91 707070-5528</span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Email: info@eazymov.com
                </span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Shop No. 20, R.K.Tower,
                </span>
                <br />
                <span style={{ fontSize: "14px" }}>Sector 3, Faridabad</span>
                <br />
                <span style={{ fontSize: "14px" }}>121004</span>
                <br />
              </div>
              <div
                className="p-3 mt-4"
                style={{ backgroundColor: "#0B60A9", color: "white" }}
              >
                <h6
                  style={{ fontSize: "18px", fontWeight: "700" }}
                  className="py-3"
                >
                  Our Office Hours
                </h6>
                <span style={{ fontSize: "14px" }}>Monday : 09.00 - 17.00</span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Tuesday : 09.00 - 17.00
                </span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Wednesday : 09.00 - 17.00
                </span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Thursday : 09.00 - 17.00
                </span>
                <br />
                <span style={{ fontSize: "14px" }}>Friday : 09.00 - 17.00</span>
                <br />
                <span style={{ fontSize: "14px" }}>
                  Saturday : 09.00 - 17.00
                </span>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <form>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div>
                      <label className="contactlabel">NAME</label>
                      <br />
                      <input
                        className="contactinp"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="contactlabel">TELEPHONE</label>
                      <br />
                      <input
                        className="contactinp"
                        type="number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div>
                      <label className="contactlabel">EMAIL</label>
                      <br />
                      <input
                        className="contactinp"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="contactlabel">
                        SELECT YOUR SERVICE
                      </label>
                      <br />
                      <select
                        className="contactinp"
                        value={service}
                        onChange={(e) => setService(e.target.value)}
                      >
                        <option>--service--</option>
                        <option>PACKERS & MOVERS</option>
                        <option>PET-RELOCATION</option>
                        <option>HIRE-TRUCK-TEMPO</option>
                        <option>TRANSPORT</option>
                        <option>WAREHOUSES</option>
                        <option>CARGO</option>
                        <option> CAR-TRANSPORT</option>
                        <option>BIKE-TRANSPORT</option>
                        <option>INTERNATIONAL MOVERS</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <label className="contactlabel">MESSAGE</label>
                      <br />
                      <textarea
                        style={{
                          width: "100%",
                          height: "156px",
                          maxHeight: "100%",
                          border: "none",
                          outline: "none",
                          boxShadow: "0px 0px 5px gray",
                          borderRadius: "10px",
                          margin: "18px 0",
                        }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </div>
                    <button
                      onClick={handleSubmit}
                      className="reqbtn"
                      type="submit"
                    >
                      SEND MESSAGE
                    </button>
                  </div>
                </div>
              </form>

              {/* {response && (
                <div>
                  {response.success ? (
                    <p style={{ color: "green" }}>{response.message}</p>
                  ) : (
                    <p style={{ color: "red" }}>{response.message}</p>
                  )}
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2710.852648031137!2d77.32353657415665!3d28.36334429625639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdd8a3acaef43%3A0xff5d5fabf55d2e19!2sdevFox%20Technology!5e1!3m2!1sen!2sin!4v1722842625120!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contactus;
