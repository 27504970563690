import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../pages/home.css"; // Your custom CSS file
import { useNavigate } from "react-router-dom";

const CardSlider = () => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const card = [
    {
      text: "Eazymov made my relocation stress-free! Their Packers & Movers service was professional and efficient. My furniture and belongings were packed with care and delivered on time. Highly recommended for anyone moving within Mumbai!",
      head: "Ravi Sharma ",
      address: "(Mumbai, Maharashtra)",
    },
    {
      text: "I used Eazymov for bike transportation from Bengaluru to Delhi. The process was smooth, and my bike arrived without a scratch. The tracking feature was especially helpful, keeping me informed throughout. Great service!",
      head: "Priya Verma",
      address: "(Bengaluru, Karnataka)",
    },
    {
      text: "The ocean freight service offered by Eazymov exceeded my expectations. My cargo was delivered safely from Chennai to Dubai, and the customer support team was always available to answer my queries. Will definitely use their services again!",
      head: "Arjun Nair",
      address: "(Chennai, Tamil Nadu)",
    },
    {
      text: "Eazymov’s warehousing service is top-notch. I needed to store my household items temporarily, and their facility was clean, secure, and affordable. The staff was friendly and helped me with all the paperwork. Thank you, Eazymov!",
      head: "Sneha Kapoor",
      address: "(Pune, Maharashtra)",
    },
    {
      text: "Hiring a tempo for my local move in Gurgaon was a breeze with Eazymov. The driver was punctual, and the vehicle was in great condition. I’m glad I chose Eazymov for my transportation needs!",
      head: "Rahul Gupta",
      address: "(Gurgaon, Haryana)",
    },
    {
      text: "I was worried about shifting my delicate kitchen appliances, but Eazymov's Packers & Movers handled everything with care. The packing materials they used were high quality, and the team was very professional. A seamless experience!",
      head: "Lakshmi Rao",
      address: "(Hyderabad, Telangana)",
    },
  ];

  return (
    <div
      className="card-slider-container"
      style={{ backgroundColor: "#0D6EFD", overflow: "hidden" }}
    >
      <div
        className="carousel-head text-center"
        style={{ textDecoration: "underline" }}
      >
        What Our Clients Say
      </div>
      <Slider {...settings}>
        {card.map((card) => (
          <div className="card-slidee">
            {/* <div className="card"> */}
            <div>
              <figure class="snip1533">
                <figcaption>
                  <blockquote>
                    <p>{card.text}</p>
                  </blockquote>
                  <h3>{card.head}</h3>
                  <h4>{card.address}</h4>
                </figcaption>
              </figure>
            </div>
            {/* </div> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardSlider;
