import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/hiretruck.png";
import "./service.css";
import FirstComp from "../comp/slide";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import LinaerStepper from "../comp/Counter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const HireTruck = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Hire-Truck-Tempo"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              HIRE TRUCK TEMPO
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"}Hire-Truck-Tempo
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Hire Truck Tempo</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, ourHire-Truck-Tempo services are designed to meet the
              demands of today’s fast-paced global market. We prioritize speed
              and reliability, ensuring that your shipments reach their
              destination promptly and efficiently. Our extensive network of
              airline partners and logistics experts work together to handle
              every aspect of air transportation, from booking to delivery.
            </p>
            <p>
              We understand that each shipment is unique, which is why we offer
              flexibleHire-Truck-Tempo solutions tailored to your specific
              needs. Whether you require express delivery for urgent cargo or a
              cost-effective option for less time-sensitive shipments, Eazymov
              provides a range ofHire-Truck-Tempo services to suit every
              requirement. Our commitment to exceptional customer service
              ensures that you receive real-time updates and support throughout
              the shipping process.
            </p>
            <p>
              At Eazymov, we are dedicated to continuous improvement and
              innovation in ourHire-Truck-Tempo operations. Our goal is to
              expand our global reach while maintaining the highest standards of
              service quality. By leveraging advanced technology and industry
              best practices, we strive to enhance the efficiency and
              reliability of ourHire-Truck-Tempo services, helping you stay
              ahead in the competitive landscape.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car-Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <div className="container">
        <h2 className="htag mt-5">
          How to Approach LogisticMart to Hire Tata 407 on Rent in India?
        </h2>
        <ul>
          The following tables are revealing the charges in which you can avail
          of the services:
          <li>
            <b>Ecco Tempo</b>
          </li>
          The given table is the general prices of these services:
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#0B60A9" }}>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Base Fare (Rs.)</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Charges per Km</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Time Charges per Min</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Rs. 250</TableCell>
                  <TableCell align="center">Rs. 12</TableCell>
                  <TableCell align="center">Rs. 2</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <li>
            <b>Tata Ace Rent Per Km</b>
          </li>
          The given table is the general prices of these services:
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#0B60A9" }}>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Base Fare (Rs.)</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Charges per Km</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Time Charges per Min</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Rs. 300</TableCell>
                  <TableCell align="center">Rs. 17</TableCell>
                  <TableCell align="center">Rs. 3</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <li>
            <b>Tata 407 Truck Rent Per Km</b>
          </li>
          The given table is the general prices of these services:
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#0B60A9" }}>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Base Fare (Rs.)</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Charges per Km</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Time Charges per Min</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Rs. 850</TableCell>
                  <TableCell align="center">Rs. 26</TableCell>
                  <TableCell align="center">Rs. 6</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <li>
            <b>Mahindra 600</b>
          </li>
          The given table is the general prices of these services:
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow style={{ backgroundColor: "#0B60A9" }}>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Base Fare (Rs.)</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Charges per Km</strong>
                  </TableCell>
                  <TableCell align="center" style={{ color: "white" }}>
                    <strong>Time Charges per Min</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">Rs. 450</TableCell>
                  <TableCell align="center">Rs. 21</TableCell>
                  <TableCell align="center">Rs. 5</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </ul>
      </div>
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Hire Truck Tempo Services
            </h1>
            <h2 className="htag mt-5">
              Introduction to Hire Truck Tempo Services
            </h2>
            <p className="ptag">
              Eazymov offers reliable and efficient truck tempo hire services
              tailored to meet your specific transportation needs. Whether
              you're relocating, delivering goods, or transporting heavy
              equipment, our fleet of well-maintained vehicles ensures a
              hassle-free experience.
            </p>
            <h2 className="htag mt-5">Wide Range of Vehicle Options</h2>
            <p className="ptag">
              Our service provides a diverse selection of trucks and tempos,
              ranging from small tempos for light cargo to larger trucks for
              heavy-duty transportation. Whatever the size of your load, we have
              the perfect vehicle to accommodate it.
            </p>
            <h2 className="htag mt-5">Professional and Experienced Drivers</h2>
            <p className="ptag">
              At Eazymov, our drivers are not only experienced but also trained
              to handle various types of cargo with care and efficiency. They
              are well-versed in safe driving practices, ensuring that your
              goods reach their destination securely.
            </p>
            <h2 className="htag mt-5">Affordable and Transparent Pricing</h2>
            <p className="ptag">
              We believe in offering competitive rates without compromising on
              quality. Our pricing structure is transparent, with no hidden
              charges, ensuring that you get the best value for your money
            </p>
            <h2 className="htag mt-5">Flexible Booking and Easy Scheduling</h2>
            <p className="ptag">
              Eazymov makes it easy to book a truck or tempo according to your
              convenience. Our flexible scheduling options allow you to choose
              the best time for your transportation needs, whether it's a
              last-minute requirement or a planned move.
            </p>
            <h2 className="htag mt-5">Safe and Secure Transportation</h2>
            <p className="ptag">
              Safety is our top priority. Our vehicles are equipped with the
              latest technology to ensure the secure transportation of your
              goods. We also provide tracking options so you can monitor your
              shipment in real-time.
            </p>
            <h2 className="htag mt-5">
              Why Choose Eazymov for Truck Tempo Hire?
            </h2>
            <p className="ptag">
              With our commitment to customer satisfaction, a wide range of
              vehicle options, and a focus on safety, Eazymov stands out as the
              trusted choice for truck tempo hire services. We strive to make
              your transportation experience smooth, efficient, and stress-free.
            </p>
          </div>
        </div>
      </div>
      <FirstComp />
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HireTruck;
