import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faWix,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";
import "./headfoot.css";

const Footer = () => {
  const InstaRedirect = () => {
    window.open("https://www.instagram.com/eazymov.com_/", "_blank");
  };
  const FaceBookRedirect = () => {
    window.open(
      "https://www.facebook.com/people/Eazymov/61559820655073/",
      "_blank"
    );
  };
  const Wix = () => {
    window.open("https://x.com/wix", "_blank");
  };
  const Youtube = () => {
    window.open("https://www.youtube.com/user/Wix", "_blank");
  };
  const Linkedin = () => {
    window.open("https://www.linkedin.com/", "_blank");
  };
  const sstcloud = () => {
    window.open("https://sstcloud.in/", "_blank");
  };

  return (
    <>
      <div className="container-fluid footer text-light">
        <div className="row py-5">
          <div
            className="col-lg-3 col-md-6 col-sm-12 px-4"
            style={{ textAlign: "justify" }}
          >
            <img src={logo} alt="logo" className="footerlogo" />
            <p>
              At Eazymov, we specialize in delivering comprehensive logistics
              solutions, from efficient warehousing and cargo management to
              reliable sea delivery services. Our commitment to excellence
              ensures your goods are handled with care and transported
              seamlessly to meet your business needs.
            </p>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h4>RECENT POST</h4>

            <div className="mt-3">
              <h6>Your Blog Title Here</h6>
              <p>
                Phasellus et nisl tellus. Etiam facilisis eu nisi scelerisque
                faucibus...
              </p>
              <p>02 August, 2015</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <h4>CONTACT US</h4>
            <h6>Our Office Address</h6>
            <span>Phone: +91 707070-5528</span>
            <br />
            <span>Email: info@eazymov.com</span>
            <br />
            <span>Shop No. 20, R.K.Tower,</span>
            <br />
            <span>Sector 3, Faridabad</span>
            <br />
            <span>121004</span>
            <br />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <h4>About Us</h4>
            <p style={{ lineHeight: "10px" }}>
              {" "}
              <Link className="navlink" to="/join-as-partner">
                Join Us As Partner
              </Link>
            </p>
            <p style={{ lineHeight: "10px" }}>
              {" "}
              <Link className="navlink" to="/buy-leads">
                Buy Leads
              </Link>
            </p>
            <p style={{ lineHeight: "10px" }}>
              {" "}
              <Link className="navlink" to="/about-us">
                About Us
              </Link>
            </p>
            <p style={{ lineHeight: "10px" }}>
              {" "}
              <Link className="navlink" to="/contact-us">
                Contact Us
              </Link>
            </p>
            <p style={{ lineHeight: "10px" }}>
              {" "}
              <Link className="navlink" to="/services">
                Services
              </Link>
            </p>

            {/* <button className="footerbtn">ORDER NOW</button>
            <Link to="/buy-leads">buy leads</Link> */}
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-3"
        style={{ backgroundColor: "#03345A", color: "white" }}
      >
        <div className="row">
          <div className="col-sm-12">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                gap: "50px",
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "24px", cursor: "pointer" }}
                icon={faFacebookF}
                onClick={FaceBookRedirect}
              />
              <FontAwesomeIcon
                style={{ fontSize: "24px", cursor: "pointer" }}
                onClick={Wix}
                icon={faWix}
              />
              <FontAwesomeIcon
                style={{ fontSize: "24px", cursor: "pointer" }}
                icon={faYoutube}
                onClick={Youtube}
              />
              <FontAwesomeIcon
                style={{ fontSize: "24px", cursor: "pointer" }}
                icon={faLinkedinIn}
                onClick={Linkedin}
              />
              <FontAwesomeIcon
                style={{ fontSize: "24px", cursor: "pointer" }}
                icon={faInstagram}
                onClick={InstaRedirect}
              />
            </div>
          </div>
        </div>
        <div className="row py-4">
          <div
            className="col-sm-12 text-center"
            style={{ fontSize: "16px", letterSpacing: "2px" }}
          >
            Copyright 2015 - 2016. All Rights Reserved. Powered by{" "}
            <Link
              style={{ color: "white", fontSize: "18px", fontWeight: "700" }}
              onClick={sstcloud}
            >
              sstcloud
            </Link>
            <br />
            <b>
              <Link className="privacy" to="/privacy-terms">
                Privacy & Terms
              </Link>
            </b>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
