import React from "react";
import Layout from "../comp/Layout";
import bg from "../img/about-page-bg.jpg";
import { Link, useNavigate } from "react-router-dom";
import "./about.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTumblr,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Foter2comp from "../comp/Footercomp2";

const Aboutus = () => {
  const navigate = useNavigate();
  const img = [
    { img: "/img/aboutslide1.jpg" },
    { img: "/img/aboutslide2.jpg" },
    { img: "/img/aboutslide3.jpg" },
  ];
  const card = [
    {
      img: "/img/aboutcard1.jpg",
      head: "Our Mission",
      text: "Our mission is to deliver excellence in logistics, ensuring reliable and efficient transport solutions that exceed our clients' expectations.",
    },
    {
      img: "/img/aboutcard2.jpg",
      head: "Our Vision",
      text: "We envision a future where our innovative logistics services set the standard for global transportation, connecting businesses and communities worldwide.",
    },
    {
      img: "/img/aboutcard3.jpg",
      head: "Expanding Goal",
      text: "Our expanding goals include broadening our network, enhancing sustainability practices, and continuously improving our services to meet the evolving needs of our clients.",
    },
  ];
  const card2 = [
    {
      img: "/img/aboutcard4.jpg",
      head: "LINDA CAROLINA",
      text: "Manager",
      nav1: "",
      nav2: "",
      nav3: "",
    },
    {
      img: "/img/aboutcard5.jpg",
      head: "MATT HARRIS",
      text: "Co Founder",
      nav1: "",
      nav2: "",
      nav3: "",
    },
    {
      img: "/img/aboutcard6.jpg",
      head: "NABILA SHAKIR",
      text: "Financial Accountant",
      nav1: "",
      nav2: "",
      nav3: "",
    },
  ];
  return (
    <Layout title={"About-Us"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>ABOUT US</h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"} About Us
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h6>About-Us</h6>
            <div
              style={{
                height: "3px",
                width: "30px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p
              style={{ textAlign: "justify", fontSize: "14px" }}
              className="mt-3"
            >
              Eazymov.com, spearheaded by the visionary Arun Malik, has been a
              beacon of efficiency in the logistics and packers movers industry
              since its inception in 2012. This company has carved a niche for
              itself by seamlessly blending technology with personalized
              services, making relocation a hassle-free experience.
            </p>
            <p
              style={{ textAlign: "justify", fontSize: "14px" }}
              className="my-3"
            >
              Under Tarun's astute leadership, Eazymov.com has become synonymous
              with reliability and professionalism. The company boasts a
              comprehensive range of services, from packing and loading to
              transportation and unpacking. Their commitment to quality is
              evident in every aspect of the relocation process. ​
            </p>
            <p
              style={{ textAlign: "justify", fontSize: "14px" }}
              className="my-3"
            >
              What sets Eazymov.com apart is its tech-savvy approach. The
              company utilizes cutting-edge tools and platforms to streamline
              operations and enhance customer experience. The online portal,
              user-friendly and intuitive, allows clients to track their
              shipments in real-time, bringing transparency to the forefront.
            </p>
            <p
              style={{ textAlign: "justify", fontSize: "14px" }}
              className="my-3"
            >
              Arun Malik's dedication to customer satisfaction is reflected in
              Eazymov.com's well-trained and courteous staff. The team
              understands the emotional and logistical challenges of relocation,
              ensuring a compassionate and efficient service. This
              customer-centric approach has earned the company a loyal clientele
              over the years.
            </p>
          </div>
          <div className="col-md-6 col-sm-12">
            <div id="carouselExampleIndicators" className="carousel slide">
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={0}
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={1}
                  aria-label="Slide 2"
                />
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to={2}
                  aria-label="Slide 3"
                />
              </div>
              <div className="carousel-inner">
                {img.map((e) => (
                  <>
                    <div className="carousel-item active">
                      <img src={e.img} className="d-block w-100" alt="..." />
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <img
                  src={e.img}
                  alt="img"
                  style={{ height: "60%", width: "100%" }}
                />
                <h5
                  style={{
                    fontSize: "19px",
                    fontWeight: "700",
                    letterSpacing: "1px",
                  }}
                  className="my-3"
                >
                  {e.head}
                </h5>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  {e.text}
                </p>
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="container-fluid ">
        <div className="row">
          <h2
            className="text-center my-5"
            style={{
              fontSize: "2.5em",
              fontWeight: "700",
              textDecoration: "underline",
              textShadow: "1px 1px 2px #1a1a1a",
            }}
          >
            OUR LEADING MEMBERS
          </h2>
        </div>
        <div className="row">
          {card2.map((e) => (
            <div className="col-lg-4 col-md-6 col-sm-12" key={e.id}>
              <div className="cards">
                <div className="outlinePage">
                  <img
                    src={e.img}
                    style={{ height: "90%", width: "100%" }}
                    alt="img"
                  />
                </div>
                <div className="detailPage">
                  <div className="gradesBox">
                    <p className="gradesBoxNum">{e.head}</p>
                    <p className="gradesBoxLabel">{e.text}</p>
                    <FontAwesomeIcon
                      style={{
                        fontSize: "20px",
                        margin: "0 10px",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => {
                        navigate(e.nav1);
                      }}
                      icon={faFacebookF}
                    />
                    <FontAwesomeIcon
                      style={{
                        fontSize: "20px",
                        margin: "0 10px",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => {
                        navigate(e.nav2);
                      }}
                      icon={faTwitter}
                    />
                    <FontAwesomeIcon
                      style={{
                        fontSize: "20px",
                        margin: "0 10px",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => {
                        navigate(e.nav3);
                      }}
                      icon={faTumblr}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Foter2comp />
    </Layout>
  );
};

export default Aboutus;
