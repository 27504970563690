import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faMessage,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const ModalComponent = () => {
  const [show, setShow] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [subServices, setSubServices] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    subService: "",
  });

  // Automatically open the modal 5 seconds after page load
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 5000); // 5000ms = 5 seconds

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const services = {
    WAREHOUSES: [
      "Household Storage",
      "Business Storage",
      "Commercial Warehouse for Rent",
      "Household Warehouse for Rent",
    ],
    CARGO: [
      "Air Cargo",
      "Rail Cargo",
      "Road Cargo",
      "Sea International Cargo",
      "Air International Cargo",
    ],
    "HIRE-TRUCK-TEMPO": [
      "Chota Hathi",
      "Tata Ace And Chota Hathi",
      "Ape Xtra (Mahindra)",
      "Mahindra Porter 600",
      "Mahindra Porter 100",
      "Tata 407",
      "Tata Ace Open",
      "Mahindra PickUp",
      "Eicher Truck",
      "Mini Truck",
    ],
    "PET-RELOCATION": ["No Sub-Services"],
    "CAR-TRANSPORTATION": [
      "Luxury Car & Bike Shifting Services",
      "Car Packers & Movers Services",
      "Bike Transportation Services",
      "SUV Car Service",
    ],
    "BIKE-TRANSPORTATION": ["No Sub-Services"],
    "INTERNATIONAL-MOVERS": [
      "Packers & Movers",
      "Household+Car",
      "Household+Bike",
      "Car-Transportation",
      "Bike-Transportation",
      "Furniture Shifting",
      "Pet-Relocation",
    ],
    "PACKERS & MOVERS": [
      "Home Shifting Services",
      "Office Shifting Services",
      "Local Shifting Services",
      "Furniture Shifting Services",
      "Bed Shifting Services",
      "TV Shifting Services",
      "Sofa Shifting Services",
      "Fridge Shifting Services",
    ],
    TRANSPORT: ["Open Truck", "Small Truck", "Container"],
  };
  const InstaRedirect = () => {
    window.open("https://www.instagram.com/eazymov.com_/", "_blank");
  };
  const FaceBookRedirect = () => {
    window.open(
      "https://www.facebook.com/people/Eazymov/61559820655073/",
      "_blank"
    );
  };

  const Youtube = () => {
    window.open("https://www.youtube.com/user/Wix", "_blank");
  };
  const Linkedin = () => {
    window.open("https://www.linkedin.com/", "_blank");
  };

  const handleServiceChange = (e) => {
    const selected = e.target.value;
    setSelectedService(selected);
    setSubServices(services[selected] || []);
    setFormData({ ...formData, service: selected, subService: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://eazymov.in:8092/api/quote/newquote",
        formData
      );
      toast.success(response?.data?.message);
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Oops! Something went wrong. Please try again later.");
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ToastContainer />

      <Modal show={show} onHide={handleClose}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#04364A",
            borderRadius: "5px",
          }}
        >
          <div className="modaldesign2">
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Control
                    type="tel"
                    placeholder="Enter Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="service">
                  <Form.Select
                    name="service"
                    value={selectedService}
                    onChange={handleServiceChange}
                    required
                  >
                    <option value="">Choose a Service...</option>
                    {Object.keys(services).map((service) => (
                      <option key={service} value={service}>
                        {service}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {subServices.length > 0 && (
                  <Form.Group className="mb-3" controlId="subService">
                    <Form.Select
                      name="subService"
                      value={formData.subService}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Choose a Subservice...</option>
                      {subServices.map((subService, index) => (
                        <option key={index} value={subService}>
                          {subService}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    onClick={handleSubmit}
                    className="btnsubmit"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button onClick={handleClose} className="btnclose">
                    close
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
          <div className="modaldesign">
            <h6 style={{ fontSize: "24px", fontWeight: "600" }}>
              Lets Talk On Something{" "}
              <span style={{ color: "#64CCC5" }}>Great</span> Together
            </h6>
            <p style={{ fontWeight: "600" }}>
              <FontAwesomeIcon icon={faMessage} style={{ margin: "0 10px" }} />{" "}
              info@eazymov.com
            </p>
            <p style={{ fontWeight: "600" }}>
              <FontAwesomeIcon icon={faPhoneAlt} style={{ margin: "0 10px" }} />
              7070705528
            </p>
            <p style={{ fontWeight: "600" }}>
              <FontAwesomeIcon icon={faLocation} style={{ margin: "0 10px" }} />{" "}
              Shop No. 20, R.K.Tower, Sector 3, Faridabad 121004
            </p>
            <div
              style={{
                backgroundColor: "#04364A",
                color: "white",
                padding: "10px 0",
                marginTop: "50px",
                width: "100%",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={InstaRedirect}
                icon={faInstagram}
              />
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={FaceBookRedirect}
                icon={faFacebookF}
              />
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={Linkedin}
                icon={faLinkedinIn}
              />
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={Youtube}
                icon={faYoutube}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalComponent;
