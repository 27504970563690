import React from "react";
import slide1 from "../img/whych1.png";
import slide2 from "../img/whych2.png";
import slide3 from "../img/whych3.png";
import slide4 from "../img/whych4.png";
import slide5 from "../img/whych5.png";
import slide6 from "../img/whych2.png";

const Card3 = () => {
  const data = [
    {
      img: slide1,
      head: "Household Shifting Service",
      text: "Eazymov offers seamless household shifting services, ensuring a stress-free relocation experience with expert handling and safe transportation of all your belongings. Trust Eazymov to make your move efficient and hassle-free from start to finish.",
    },
    {
      img: slide2,
      head: "Packers Movers Services",
      text: "Eazymov's Packers and Movers services guarantee a hassle-free relocation with expert packing, secure transportation, and prompt delivery. Trust Eazymov to handle your move with care and efficiency, ensuring your belongings reach their destination safely.",
    },
    {
      img: slide3,
      head: "Relocation Service",
      text: "Eazymov's relocation service offers a smooth transition to your new home or office with professional support, precise planning, and secure transport. Experience a stress-free move with Eazymov, where every detail is handled with care and efficiency.",
    },
    {
      img: slide4,
      head: "Car Carriers Movers Services",
      text: "Eazymov’s Car Carriers Movers Services ensure the safe and timely transport of your vehicle with expert handling and secure logistics. Trust Eazymov for a reliable and hassle-free car relocation experience.",
    },
    {
      img: slide5,
      head: "Loading & Unloading Services",
      text: "Eazymov provides efficient loading and unloading services, ensuring your belongings are handled with care and precision. Our expert team guarantees a smooth and timely process, minimizing any risk of damage during your move.",
    },
    {
      img: slide6,
      head: "Transport Services",
      text: "Eazymov’s transport services offer reliable and timely delivery, ensuring your goods reach their destination safely and efficiently. With a focus on customer satisfaction, Eazymov guarantees a seamless transport experience for all your needs.",
    },
  ];
  return (
    <div className="container-fluid p-5">
      <div className="row py-5">
        <div
          className="col-sm-12 text-center"
          style={{
            fontSize: "2.5em",
            fontWeight: "700",
            textDecoration: "underline",
            textShadow: "1px 1px 2px #1a1a1a",
          }}
        >
          Why Choose Us
        </div>
      </div>
      <div className="row">
        {data.map((e) => (
          <>
            <div className="col-lg-4 col-md-6 col-sm-12 my-4">
              <div className="row">
                <div className="col-sm-5">
                  <img
                    src={e.img}
                    alt="img"
                    style={{
                      height: "155px",
                      width: "130px",
                      paddingTop: "30px",
                    }}
                  />
                </div>
                <div className="col-sm-7">
                  <h6
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      paddingTop: "10px",
                    }}
                  >
                    {e.head}
                  </h6>
                  <p
                    style={{
                      fontSize: "14px",
                      textAlign: "justify",
                      paddingTop: "10px",
                    }}
                  >
                    {e.text}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Card3;
