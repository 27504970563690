import { Route, Routes } from "react-router-dom";
import "./App.css";
import Homepage from "./pages/Homepage";
import Aboutus from "./pages/Aboutus";
import Services from "./pages/Services";
import Contactus from "./pages/Contactus";

import Transport from "./pages/OceanNetwork";
import CarTransport from "./pages/AirFreight";
import Warehouse from "./pages/Warehouse";
import Packers from "./pages/Storage";
import Cargo2 from "./pages/Cargo2";
import AdminRoute from "./comp/Admin/AdminRoute";
import AdminDashboard from "./comp/Admin/AdminDashboard";
import UpdateProfile from "./comp/Admin/profile";
import Products from "./comp/Admin/Product";
import AlluserRoute from "./comp/Admin/AllusersRoute";
import AllUsers from "./comp/Admin/AllUsers";
import AllQueries from "./comp/Admin/AllQueries";
import Plans from "./comp/Admin/Plans";
import UpdatePlan from "./comp/Admin/UpdatePlans";
import FilterQuery from "./comp/Admin/Quries";
import PetRelocate from "./pages/PetRe";
import HireTruck from "./pages/HireTruck";
import International from "./pages/InternationalMovers";
import BikeTransport from "./pages/Overland";
import PrivacyTerms from "./pages/Privacy&Terms";
import Buylead from "./pages/Buyleads";
import JoinAsPartner from "./pages/Joinaspart";
import BuyLeadQuery from "./comp/Admin/BuyLeadQury";
import JoinaspartQury from "./comp/Admin/Joinaspartqury";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/bike-transportation" element={<BikeTransport />} />
        <Route path="/transport" element={<Transport />} />
        <Route path="/car-transportation" element={<CarTransport />} />
        <Route path="/warehouse" element={<Warehouse />} />
        <Route path="/packers-movers" element={<Packers />} />
        <Route path="/cargo" element={<Cargo2 />} />
        <Route path="/pet-relocation" element={<PetRelocate />} />
        <Route path="/hire-truck-tempo" element={<HireTruck />} />
        <Route path="/international-movers" element={<International />} />
        <Route path="/privacy-terms" element={<PrivacyTerms />} />
        <Route path="/buy-leads" element={<Buylead />} />
        <Route path="/join-as-partner" element={<JoinAsPartner />} />

        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/profile/:_id" element={<UpdateProfile />} />

          <Route path="admin/product" element={<Products />} />
          <Route path="admin/allusersRoute" element={<AlluserRoute />}>
            <Route path="users" element={<AllUsers />} />
          </Route>
          <Route path="admin/allqueries" element={<AllQueries />} />
          <Route path="admin/buyleadsquery" element={<BuyLeadQuery />} />
          <Route path="admin/joinaspartnerleads" element={<JoinaspartQury />} />
          <Route path="admin/plan" element={<Plans />} />
          <Route path="admin/updateplan/:_id" element={<UpdatePlan />} />
          <Route path="admin/allquery/:title" element={<FilterQuery />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
