import { useEffect, useState } from "react";
import { useAuth } from "../auth/Auth";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";

const AdminRoute = () => {
  const [auth] = useAuth();
  const [ok, setOk] = useState(null); // null: loading, true: authorized, false: unauthorized
  const navigate = useNavigate();

  useEffect(() => {
    const authCheck = async () => {
      try {
        const res = await axios.get(
          "https://eazymov.in:8092/api/user/dashboard-admin",
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Attach the token to the request
            },
          }
        );
        if (res.status === 200) {
          setOk(true);
        } else {
          setOk(false);
        }
      } catch (error) {
        console.error("Authorization error:", error);
        setOk(false);
      }
    };

    if (auth?.token) {
      authCheck();
    } else {
      setOk(false); // No token, unauthorized
    }
  }, [auth?.token]);

  // Show the spinner while loading
  if (ok === null) {
    return <Spinner path="" />;
  }

  // Redirect to login if unauthorized
  if (!ok) {
    navigate("/dashboard/admin"); // Or whichever route you want to redirect to
    return null; // Prevent further rendering
  }

  // Render the protected content
  return <Outlet />;
};

export default AdminRoute;
