// src/pages/CardSlider.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css"; // Your custom CSS file
import { useNavigate } from "react-router-dom";

const CardSlider = () => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      title: "CAR TRANSPORTATION",
      description:
        "Our storage facilities provide optimal conditions for preserving your goods, whether for short-term or long-term needs.",
      image: "/img/cartrans.webp",
      to: "car-transportation",
    },
    {
      id: 2,
      title: "WAREHOUSING",
      description:
        "We offer secure and organized warehousing solutions, keeping your goods safe and ready for distribution.",
      image: "/img/slider2.5.jpg",
      to: "warehouse",
    },
    {
      id: 3,
      title: "BIKE TRANSPORTATION",
      description:
        "Our extensive overland network ensures your cargo is transported swiftly and securely across the country.",
      image: "/img/biketrans.jpeg",
      to: "bike-transportation",
    },
    {
      id: 4,
      title: "TRANSPORT",
      description:
        "Leveraging a robust ocean network, we connect global ports to deliver your goods across the seas efficiently.",
      image: "/img/transort.jpeg",
      to: "transport",
    },
    {
      id: 5,
      title: "PACKERS & MOVERS",
      description:
        "Our air freight services provide rapid and reliable transport for time-sensitive shipments worldwide.",
      image: "/img/packer.jpg",
      to: "packers-movers",
    },
    {
      id: 6,
      title: "CARGO",
      description:
        "We expertly manage your cargo, ensuring safe and efficient transportation from origin to destination.",
      image: "/img/slider2.3.jpg",
      to: "cargo",
    },
    {
      id: 7,
      title: "PET RELOCATION",
      description:
        "We expertly manage your cargo, ensuring safe and efficient transportation from origin to destination.",
      image: "/img/petre.jpeg",
      to: "pet-relocation",
    },
    {
      id: 8,
      title: "HIRE TRUCK TEMPO",
      description:
        "We expertly manage your cargo, ensuring safe and efficient transportation from origin to destination.",
      image: "/img/hiretruck.jpeg",
      to: "hire-truck-tempo",
    },
    {
      id: 9,
      title: "INTERNATIONAL MOVERS",
      description:
        "We expertly manage your cargo, ensuring safe and efficient transportation from origin to destination.",
      image: "/img/internationalmover.jpg",
      to: "international-movers",
    },
  ];

  return (
    <div className="card-slider-container">
      <div
        className="carousel-head text-center"
        style={{ textDecoration: "underline" }}
      >
        OUR SERVICES
      </div>
      <Slider {...settings}>
        {cards.map((card) => (
          <div key={card.id} className="card-slide">
            <div className="card">
              <div
                onClick={() => {
                  navigate(`/${card.to}`);
                }}
                style={{
                  backgroundImage: `url(${card.image})`,
                  backgroundPosition: "center",
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                  height: "345",
                  backgroundSize: "cover",
                  cursor: "pointer",
                  width:"100%",
                  maxWidth:"700px"
                }}
                className="card-image"
              >
                <div className="card-content text-center pt-5">
                  <h3 className="pt-5">{card.title}</h3>
                  <p>{card.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CardSlider;
