import React, { useState, useEffect } from "react";
import "./headfoot.css";

const Foter2comp = () => {
  const card = [
    {
      number: 1273,
      head: "Delivered Packages",
      text: "The core values are the guiding principles that dictate behavior and action.",
    },
    {
      number: 473754,
      head: "KM Per Year",
      text: "The core values are the guiding principles that dictate behavior and action.",
    },
    {
      number: 25,
      head: "Years of Experience",
      text: "The core values are the guiding principles that dictate behavior and action.",
    },
    {
      number: 719,
      head: "Happy Clients",
      text: "The core values are the guiding principles that dictate behavior and action.",
    },
    {
      number: 4234,
      head: "Tons of Goods",
      text: "The core values are the guiding principles that dictate behavior and action.",
    },
  ];

  const [currentValues, setCurrentValues] = useState(card.map(() => 0));

  useEffect(() => {
    card.forEach((item, index) => {
      let startValue = 0;
      const endValue = item.number;
      const duration = 2000; // 2 seconds
      const increment = endValue / (duration / 50); // 50ms interval

      const interval = setInterval(() => {
        startValue += increment;
        if (startValue >= endValue) {
          startValue = endValue;
          clearInterval(interval);
        }
        setCurrentValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[index] = Math.floor(startValue);
          return newValues;
        });
      }, 50);
    });
  }, []);

  return (
    <>
      {/* <div className="container-fluid foter p-5">
      <div className="row">
        <div className="col-sm-12 d-flex justify-content-around flex-wrap">
          {card.map((e, index) => (
            <div key={index} style={{ width: "200px", color: "white" }}>
              <h4
                style={{
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#0B60A9",
                }}
              >
                {currentValues[index].toLocaleString()}
              </h4>
              <div
                style={{
                  height: "5px",
                  width: "50px",
                  backgroundColor: "#0B60A9",
                  border: "none",
                  margin: "10px 0",
                }}
              ></div>
              <h6
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "10px 0",
                }}
              >
                {e.head}
              </h6>
              <p style={{ fontSize: "14px" }}>{e.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div> */}
    </>
  );
};

export default Foter2comp;
