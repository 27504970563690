import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";

const Header2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container-fluid d-flex justify-content-between">
      <img className="footerlogo" src={logo} alt="logo" />

      <div>
        <button
          className="btn"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <FontAwesomeIcon style={{ fontSize: "30px" }} icon={faBars} />
        </button>
        <div
          style={{ width: "300px" }}
          className="offcanvas offcanvas-end"
          tabIndex={-1}
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            {/* <h5 className="offcanvas-title" id="offcanvasRightLabel"></h5> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            />
          </div>
          <div className="offcanvas-body">
            <ul
              style={{
                listStyle: "none",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <li>
                <Link className="head2link" to="/">
                  HOME
                </Link>
              </li>
              <li>
                <Link className="head2link" to="/about-us">
                  ABOUT US
                </Link>
              </li>
              <li>
                {/* <Link className="head2link" to="/services">
                  SERVICES
                </Link> */}
                <div className="dropdown">
                  <Link
                    className="head2link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    SERVICES
                  </Link>
                  <ul
                    style={{
                      padding: "0 5px",
                      margin: "0",
                      position: "absolute",
                      left: "20%",
                    }}
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/packers-movers">
                          Packers & Movers
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/car-transportation">
                          Car Transportation
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/hire-truck-tempo">
                          Hire Truck Tempo
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/cargo">
                          Cargo
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/transport">
                          Transport
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/warehousing">
                          Warehouse
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link
                          className="head2linktag"
                          to="/bike-transportation"
                        >
                          Bike Transportation
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link
                          className="head2linktag"
                          to="/international-movers"
                        >
                          International Movers
                        </Link>
                      </a>{" "}
                    </li>
                    <li>
                      <a style={{ borderBottom: "1px solid gray" }}>
                        <Link className="head2linktag" to="/pet-relocation">
                          Pet Relocation
                        </Link>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link className="head2link" to="/contact-us">
                  BLOG
                </Link>
              </li>
              <li>
                <Link className="head2link">CONTACT</Link>
              </li>
            </ul>
          </div>
          <div className="offcanvas-body">
            <div
              style={{
                color: "white",
                border: "1px solid white",
                padding: "10px",
              }}
            >
              <p style={{ fontSize: "16px" }}>
                Opening Hours
                <br />
                <span style={{ fontWeight: "600" }}>
                  Mon - Sat: 09.00 - 17.00
                </span>
              </p>{" "}
              <p style={{ fontSize: "16px" }}>
                Call Us
                <br />
                <span style={{ fontWeight: "600" }}>707-070-5528</span>
              </p>{" "}
              <p style={{ fontSize: "16px" }}>
                Email Us
                <br />
                <span style={{ fontWeight: "600" }}>info@eazymov.com</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header2;
