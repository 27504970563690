import React from "react";
import { NavLink } from "react-router-dom";
import userlist from "../../img/userlist.png";
import userlist4 from "../../img/db.png";
import userlist2 from "../../img/enqury.png";
// import userlist2 from "../../img/ourplan2.jpeg";
import userlist3 from "../../img/products.jpeg";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";

const SideNav = () => {
  return (
    <Drawer variant="permanent">
      <div className="side-nav-container">
        <div className="side-nav-content">
          <List>
            <ListItem
              button
              component={NavLink}
              to="/dashboard/admin"
              exact
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist4}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <Divider />
            <ListItem
              button
              component={NavLink}
              to="/dashboard/admin/allusersRoute/users"
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="User List" />
            </ListItem>
            {/* <ListItem
              button
              component={NavLink}
              to="/dashboard/admin/plan"
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist3}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="Plans List" />
            </ListItem> */}
            {/* <ListItem
              button
              component={NavLink}
              to=""
              activeClassName="active-link"
            >
              <ListItemIcon>
                <FontAwesomeIcon icon={faClock} />
              </ListItemIcon>
              <ListItemText primary="Audit Logs" />
            </ListItem> */}
            {/* <ListItem
              button
              component={NavLink}
              to="/dashboard/admin/product"
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist3}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </ListItem> */}
            <ListItem
              button
              component={NavLink}
              to="/dashboard/admin/allqueries"
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist2}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="Enquiry" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/dashboard/admin/buyleadsquery"
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist2}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="All Orders" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/dashboard/admin/joinaspartnerleads"
              activeClassName="active-link"
            >
              <ListItemIcon>
                <img
                  src={userlist2}
                  alt="icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </ListItemIcon>
              <ListItemText primary="Join as Partner Leads" />
            </ListItem>
            <Divider />
          </List>
        </div>
      </div>
    </Drawer>
  );
};

export default SideNav;
