import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/petre.jpg";
import "./service.css";
import FirstComp from "../comp/slide";
import Steps from "../comp/Steps";
import AccordionExpandIcon from "../comp/Faq";
import LinaerStepper from "../comp/Counter";

const PetRelocate = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Car-Transportation"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              PET RELOCATION
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Pet Relocation
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Pet Relocation</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, our Pet Relocation services are designed to meet the
              demands of today’s fast-paced global market. We prioritize speed
              and reliability, ensuring that your shipments reach their
              destination promptly and efficiently. Our extensive network of
              airline partners and logistics experts work together to handle
              every aspect of air transportation, from booking to delivery.
            </p>
            <p>
              We understand that each shipment is unique, which is why we offer
              flexible Pet Relocation solutions tailored to your specific needs.
              Whether you require express delivery for urgent cargo or a
              cost-effective option for less time-sensitive shipments, Eazymov
              provides a range of Pet Relocation services to suit every
              requirement. Our commitment to exceptional customer service
              ensures that you receive real-time updates and support throughout
              the shipping process.
            </p>
            <p>
              At Eazymov, we are dedicated to continuous improvement and
              innovation in our Pet Relocation operations. Our goal is to expand
              our global reach while maintaining the highest standards of
              service quality. By leveraging advanced technology and industry
              best practices, we strive to enhance the efficiency and
              reliability of our Pet Relocation services, helping you stay ahead
              in the competitive landscape.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Pet Relocation Services
            </h1>
            <h2 className="htag mt-5">Safe and Comfortable Pet Relocation</h2>
            <p className="ptag">
              At Eazymov, we understand that pets are more than just animals;
              they are part of your family. Our pet relocation services are
              designed to ensure that your furry friends are transported safely
              and comfortably, whether you're moving across the country or
              across the globe. We take the stress out of pet relocation by
              handling all the logistics, so you can focus on settling into your
              new home.
            </p>
            <h2 className="htag mt-5">Customized Travel Plans for Your Pets</h2>
            <p className="ptag">
              Every pet is unique, and their travel needs can vary widely.
              That's why Eazymov offers customized travel plans tailored to the
              specific needs of your pet. From specialized crates to
              temperature-controlled environments, we ensure that your pet’s
              journey is as smooth as possible. Our team works closely with you
              to create a relocation plan that takes into account your pet’s
              size, breed, and any special requirements they may have.
            </p>
            <h2 className="htag mt-5">Expert Handling and Care</h2>
            <p className="ptag">
              Our team of experienced handlers and veterinarians is dedicated to
              providing the highest level of care for your pets throughout the
              relocation process. We adhere to strict safety standards and
              regulations to ensure that your pet is treated with the utmost
              care. From pre-travel health checks to in-transit monitoring, we
              go the extra mile to ensure your pet's well-being.
            </p>
            <h2 className="htag mt-5">
              Hassle-Free Documentation and Compliance
            </h2>
            <p className="ptag">
              Relocating pets often involves complex documentation and
              compliance with international regulations. Eazymov’s pet
              relocation service includes comprehensive support with all the
              necessary paperwork, including health certificates, import/export
              permits, and customs clearance. Our experts are well-versed in the
              requirements of different countries and will guide you through the
              process, ensuring that your pet arrives at your new home without
              any hitches.
            </p>
            <h2 className="htag mt-5">Stress-Free Door-to-Door Service</h2>
            <p className="ptag">
              With Eazymov, you can enjoy peace of mind knowing that our
              door-to-door service takes care of every detail of your pet’s
              relocation. From pickup at your current location to safe delivery
              at your new home, our team handles everything. We provide
              real-time updates throughout the journey, so you’re always in the
              loop about your pet’s status.
            </p>
            <h2 className="htag mt-5">
              Why Choose Eazymov for Pet Relocation?
            </h2>
            <p className="ptag">
              Choosing Eazymov means choosing a team that is committed to
              providing the best possible relocation experience for your pets.
              With our comprehensive services, expert care, and attention to
              detail, you can trust us to deliver your pets safely to their new
              home. We’re not just moving pets; we’re relocating family members
              with the love and care they deserve.
            </p>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid py-5">
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-lg-3 col-md-12 ">
                <div className="p-3">
                  <div
                    className="row"
                    style={{
                      boxShadow: "2px 2px 10px lightgray",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="col-sm-2">
                      <img
                        src={e.img}
                        alt="img"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <div className="col-sm-10">
                      <h4
                        style={{
                          fontSize: "18",
                          fontWeight: "700",
                          paddingTop: "6px",
                        }}
                      >
                        {e.head}
                      </h4>
                      <p style={{ fontSize: "14px" }}>{e.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div> */}
      <FirstComp />
      <AccordionExpandIcon />

      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PetRelocate;
