import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/transportation.jpg";
import "./service.css";
import FirstComp from "../comp/slide";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import LinaerStepper from "../comp/Counter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(from, to, oneBHK, twoBHK, threeBHK, fewItems) {
  return { from, to, oneBHK, twoBHK, threeBHK, fewItems };
}

const rows = [
  createData("14 feet", "Rs 3,600 - 13,500", "Rs. 9,500 - 23,500"),
  createData("17 to 19 feet", "Rs 6,500 - 15,500", "Rs. 10,500 - 25,500"),
  createData("21 to 32 feet", "Rs 7,500 - 18,500", "Rs. 12,000 - 35,500"),
];

const Transport = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Transport"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>TRANSPORT</h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Transport
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Transport</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, our Transport service is designed to provide seamless
              and efficient shipping solutions across the globe. Leveraging our
              extensive network of international partners and strategic port
              connections, we ensure that your cargo is transported with
              precision and reliability. Whether you need to move goods across
              continents or within regional waters, our ocean freight solutions
              are tailored to meet your specific needs.
            </p>
            <p>
              Our dedicated team of logistics experts manages every aspect of
              your ocean shipment, from booking and documentation to tracking
              and delivery. We utilize advanced technology to monitor your cargo
              throughout its journey, providing real-time updates and ensuring
              that any potential issues are addressed promptly. With Eazymov,
              you can trust that your goods will arrive safely and on time.
            </p>
            <p>
              Committed to sustainability and efficiency, our Transport
              incorporates best practices to minimize environmental impact and
              optimize shipping routes. We continuously seek innovative
              solutions to enhance our service offerings and expand our global
              reach, aiming to provide the highest level of service to our
              clients. Choose Eazymov for a dependable and forward-thinking
              approach to ocean freight.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="htag my-3">Truck Transport Rates in India</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#0B60A9" }}>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  Vehicle Type
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  0km To 250km
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  251km To 500km
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={`${row.from}-${row.to}`}>
                  <TableCell align="center">{row.from}</TableCell>
                  <TableCell align="center">{row.to}</TableCell>
                  <TableCell align="center">{row.oneBHK}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Transport Services
            </h1>
            <h2 className="htag mt-5">
              Introduction to Eazymov Transport Services
            </h2>
            <p className="ptag">
              Eazymov offers comprehensive transport services designed to meet
              the diverse needs of our clients. With a robust network and a
              commitment to reliability, we ensure that your goods are
              transported safely, efficiently, and on time. Whether you need to
              move products domestically or internationally, Eazymov is your
              trusted partner in logistics.
            </p>
            <h2 className="htag mt-5">Domestic Transport Solutions</h2>
            <p className="ptag">
              Our domestic transport services cover the entire nation, providing
              seamless door-to-door delivery for a wide range of goods.
              Eazymov's fleet of modern vehicles is equipped to handle
              everything from small parcels to large shipments, ensuring your
              products reach their destination in perfect condition. We offer
              flexible scheduling, real-time tracking, and customized solutions
              to meet your specific needs.
            </p>
            <h2 className="htag mt-5">International Freight Services</h2>
            <p className="ptag">
              Eazymov specializes in international freight, offering both air
              and ocean transport options. Our global network of partners allows
              us to navigate the complexities of international shipping with
              ease. From customs clearance to documentation, we handle all
              aspects of the process, ensuring that your goods arrive at their
              international destination without delays or complications.
            </p>
            <h2 className="htag mt-5">Specialized Transport Services</h2>
            <p className="ptag">
              At Eazymov, we understand that some goods require special
              handling. Whether it's perishable items, hazardous materials, or
              oversized cargo, our specialized transport services are designed
              to cater to these unique needs. Our team of experts is trained to
              manage these challenges, ensuring safe and compliant transport
              every time.
            </p>
            <h2 className="htag mt-5">Technology-Driven Transport</h2>
            <p className="ptag">
              Eazymov leverages the latest technology to enhance our transport
              services. Our state-of-the-art tracking systems provide real-time
              updates on the status of your shipment, allowing you to monitor
              progress every step of the way. We also use data analytics to
              optimize routes, reduce costs, and improve delivery times.
            </p>
            <h2 className="htag mt-5">Customer-Centric Approach</h2>
            <p className="ptag">
              Customer satisfaction is at the heart of Eazymov's transport
              services. We work closely with our clients to understand their
              needs and provide tailored solutions that exceed expectations. Our
              dedicated customer support team is available 24/7 to assist with
              any inquiries, ensuring a smooth and hassle-free experience.
            </p>
            <h2 className="htag mt-5">Sustainability in Transport</h2>
            <p className="ptag">
              Eazymov is committed to reducing our environmental impact through
              sustainable transport practices. We are continually investing in
              eco-friendly vehicles and exploring innovative ways to reduce our
              carbon footprint. Our goal is to provide top-tier transport
              services while also contributing to a greener future.
            </p>
          </div>
        </div>
      </div>
      <FirstComp />
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Transport;
