import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/cartrans.webp";
import "./service.css";
import FirstComp from "../comp/slide";
import Steps from "../comp/Steps";
import AccordionExpandIcon from "../comp/Faq";
import LinaerStepper from "../comp/Counter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(
  serviceType,
  upTo400Km,
  from400To900Km,
  from800To1300Km,
  from1300To1900Km
) {
  return {
    serviceType,
    upTo400Km,
    from400To900Km,
    from800To1300Km,
    from1300To1900Km,
  };
}

function createData3(
  engineType,
  upTo400Km,
  from400To800Km,
  from800To1300Km,
  from1300To1900Km
) {
  return {
    engineType,
    upTo400Km,
    from400To800Km,
    from800To1300Km,
    from1300To1900Km,
  };
}
const rows = [
  createData(
    "Hatchback Car Carrier Price",
    "3,000 - 5,000",
    "4,000 - 7,000",
    "5,000 - 9,000",
    "6,000 - 10,000"
  ),
  createData(
    "Sedan Car Carrier Price",
    "7,000 - 12,000",
    "12,000 - 17,000",
    "15,000 - 20,000",
    "20,000 - 25,000"
  ),
  createData(
    "SUV Car Carrier Price",
    "13,000 - 17,000",
    "17,000 - 22,000",
    "20,000 - 25,000",
    "25,000 - 29,000"
  ),
  createData(
    "Luxury Car Carrier Price",
    "18,000 - 23,000",
    "23,000 - 26,000",
    "25,000 - 30,000",
    "30,000 - 35,000"
  ),
  createData(
    "Sports Car Carrier Price",
    "20,000 - 25,000",
    "25,000 - 28,000",
    "26,000 - 31,000",
    "31,000 - 36,000"
  ),
];

const rows3 = [
  createData3(
    "100cc - 150cc Bike",
    "2,000 - 3,500",
    "3,000 - 4,000",
    "3,500 - 4,500",
    "4,000 - 5,000"
  ),
  createData3(
    "150cc - 200cc Bike",
    "2,400 - 3,600",
    "3,400 - 4,100",
    "3,900 - 4,600",
    "4,400 - 4,900"
  ),
  createData3(
    "200cc - 250cc Bike",
    "2,700 - 3,900",
    "3,700 - 4,400",
    "4,200 - 4,900",
    "4,700 - 5,000"
  ),
  createData3(
    "250cc - 350cc Bike",
    "2,800 - 3,800",
    "3,800 - 4,300",
    "4,300 - 4,800",
    "4,800 - 5,000"
  ),
  createData3(
    "350cc - 500cc Bike",
    "2,800 - 3,800",
    "3,800 - 4,300",
    "4,300 - 4,800",
    "4,800 - 5,000"
  ),
  createData3(
    "Sports Bike",
    "4,500 - 7,000",
    "6,000 - 9,000",
    "8,000 - 12,000",
    "11,000 - 15,000"
  ),
  createData3(
    "Cruiser Sports Bike",
    "5,000 - 8,500",
    "7,000 - 10,500",
    "9,000 - 13,500",
    "12,000 - 16,500"
  ),
  createData3(
    "Off-Road Sports Bike",
    "6,000 - 9,000",
    "7,500 - 11,500",
    "10,000 - 14,500",
    "13,000 - 17,500"
  ),
  createData3(
    "Intercity Car Transport Charges",
    "4,000 - 8,000",
    "6,000 - 10,000",
    "8,000 - 13,000",
    "11,000 - 18,000"
  ),
];

const CarTransport = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Car-Transportation"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              CAR TRANSPORTATION
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Car Transportation
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Car Transportation</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, our Car Transportation services are designed to meet
              the demands of today’s fast-paced global market. We prioritize
              speed and reliability, ensuring that your shipments reach their
              destination promptly and efficiently. Our extensive network of
              airline partners and logistics experts work together to handle
              every aspect of air transportation, from booking to delivery.
            </p>
            <p>
              We understand that each shipment is unique, which is why we offer
              flexible Car Transportation solutions tailored to your specific
              needs. Whether you require express delivery for urgent cargo or a
              cost-effective option for less time-sensitive shipments, Eazymov
              provides a range of Car Transportation services to suit every
              requirement. Our commitment to exceptional customer service
              ensures that you receive real-time updates and support throughout
              the shipping process.
            </p>
            <p>
              At Eazymov, we are dedicated to continuous improvement and
              innovation in our Car Transportation operations. Our goal is to
              expand our global reach while maintaining the highest standards of
              service quality. By leveraging advanced technology and industry
              best practices, we strive to enhance the efficiency and
              reliability of our Car Transportation services, helping you stay
              ahead in the competitive landscape.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Car Transportation Services
            </h1>
            <h2 className="htag mt-5">Reliable Car Transportation Services</h2>
            <p className="ptag">
              At Eazymov, we understand how important your vehicle is to you.
              Whether you’re relocating, selling a car, or need to transport
              your vehicle for any reason, our reliable car transportation
              services ensure that your vehicle reaches its destination safely
              and on time. We offer a variety of transportation options to suit
              your needs, including open and enclosed transport, giving you the
              flexibility to choose the level of protection for your vehicle.
            </p>
            <h2 className="htag mt-5">Door-to-Door Convenience</h2>
            <p className="ptag">
              Our door-to-door car transportation service is designed for your
              convenience. We pick up your vehicle from your specified location
              and deliver it directly to your desired destination. This
              hassle-free service saves you time and effort, allowing you to
              focus on other aspects of your move or sale while we take care of
              the logistics.
            </p>
            <h2 className="htag mt-5">Experienced and Professional Drivers</h2>
            <p className="ptag">
              Eazymov employs experienced and professional drivers who are
              trained to handle vehicles of all types. Our drivers follow strict
              safety protocols to ensure that your car is transported with the
              utmost care. Whether you’re moving a family sedan, a luxury sports
              car, or a vintage collectible, you can trust our team to handle
              your vehicle with precision and care.
            </p>
            <h2 className="htag mt-5">Secure and Insured Transport</h2>
            <p className="ptag">
              Your peace of mind is our priority. Eazymov’s car transportation
              services include comprehensive insurance coverage, so you can rest
              easy knowing that your vehicle is protected against any unforeseen
              circumstances. Additionally, our transport vehicles are equipped
              with advanced security systems to ensure the safety of your car
              during transit.
            </p>
            <h2 className="htag mt-5">
              Flexible Scheduling and Real-Time Tracking
            </h2>
            <p className="ptag">
              We offer flexible scheduling options to accommodate your busy
              lifestyle. Whether you need your car transported immediately or
              have a specific date in mind, Eazymov will work with you to find
              the best timing for your needs. With our real-time tracking
              feature, you can monitor the progress of your vehicle throughout
              its journey, keeping you informed every step of the way.
            </p>
          </div>
        </div>
      </div>
      <FirstComp />
      <div className="container my-5">
        <h2 className="htag mt-5">
          Car Transportation Charges in India According to Distance
        </h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="car carrier price table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#0B60A9" }}>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>Service Type</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>Up to 400 Km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>400 – 900 Km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>800 – 1300 Km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>1300 – 1900 Km</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.serviceType}>
                  <TableCell align="center">{row.serviceType}</TableCell>
                  <TableCell align="center">{row.upTo400Km}</TableCell>
                  <TableCell align="center">{row.from400To900Km}</TableCell>
                  <TableCell align="center">{row.from800To1300Km}</TableCell>
                  <TableCell align="center">{row.from1300To1900Km}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="container my-5">
        <h2 className="htag mt-5">
          The Following are the Car and Bike Transportation Charges in India:
        </h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="transportation cost table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#0B60A9" }}>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>Engine/Power/Types</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>Up to 400 Km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>400 – 800 Km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>800 – 1300 Km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong style={{ color: "white" }}>1300 – 1900 Km</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows3.map((row) => (
                <TableRow key={row.engineType}>
                  <TableCell align="center">{row.engineType}</TableCell>
                  <TableCell align="center">{row.upTo400Km}</TableCell>
                  <TableCell align="center">{row.from400To800Km}</TableCell>
                  <TableCell align="center">{row.from800To1300Km}</TableCell>
                  <TableCell align="center">{row.from1300To1900Km}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CarTransport;
