// src/pages/Carousel1.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css"; // Your custom CSS file

const Carousel1 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const slides = [
    {
      image: "/img/slide1home.jpg",
      text: "Best Car Movers Services in India, Get 3 Free  Quotes. Save Up To 50% Move Your Car, Office, Home, Vehicle with Leading Packers and Movers Move Your Car, Office, Home, Vehicle with Leading Packers and Movers",
      head: "Passengers Transport",
    },
    {
      image: "/img/slide2home.jpg",
      text: "Best Car Movers Services in India, Get 3 Free  Quotes. Save Up To 50% Move Your Car, Office, Home, Vehicle with Leading Packers and Movers Move Your Car, Office, Home, Vehicle with Leading Packers and Movers",
      head: "We Deliver Your Goods Fast",
    },
  ];

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="carousel-slide">
            <div
              className="carousel-background"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="carousel-head ">{slide.head}</div>
              {/* <hr
                style={{
                  height: "5px",
                  width: "200px",
                  backgroundColor: "black",
                  color: "white",
                }}
              /> */}
              <div
                style={{
                  height: "5px",
                  width: "100px",
                  backgroundColor: "white",
                  border: "none",
                  margin: "10px 0",
                }}
              ></div>
              <div className="carousel-text">{slide.text}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel1;
