import React from "react";

const Steps = () => {
  const card = [
    {
      img: "/img/step1.webp",
      head1: "Step 1",
      head2: "Submit Your Requirement",
      text: "What kind of goods you want to shift through professional Packers & Movers and at what time.",
    },
    {
      img: "/img/step2.webp",
      head1: "Step 2",
      head2: "Get A Free Quote",
      text: "Get 4 free shifting quotations from the best packers and movers",
    },
    {
      img: "/img/step3.webp",
      head1: "Step 3",
      head2: "Compare and Hire",
      text: "Compare shifting quotes, hire the best movers that suits your budget.",
    },
    {
      img: "/img/step4.webp",
      head1: "Step 4",
      head2: "We Got You Moved",
      text: "Confirm Date, Time of Booking and Schedule Your Move",
    },
  ];
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-sm-12">
          <h4>Relocate In 4 Easy Steps</h4>
        </div>
      </div>
      <div
        className="row text-center"
        style={{ display: "flex", justifyContent: "center" }}
      >
        {card.map((e) => (
          <>
            <div className="col-lg-3 col-md-6 col-sm-12 py-2 text-center">
              <img
                src={e.img}
                style={{ height: "40px", width: "36px" }}
                alt="img"
              />
              <h2
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#074D7A",
                }}
              >
                {e.head1}
              </h2>
              <h3 style={{ fontSize: "14px", fontWeight: "700" }}>{e.head2}</h3>
              <p style={{ fontSize: "14px" }}>{e.text}</p>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default Steps;
