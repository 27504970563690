import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/cargo.webp";
import "./service.css";
import FirstComp from "../comp/slide";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import LinaerStepper from "../comp/Counter";

const Cargo2 = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Cargo"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>CARGO</h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Cargo
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Cargo</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, we specialize in comprehensive cargo management
              solutions designed to ensure the safe and efficient transport of
              your goods. Our expertise spans various types of cargo, from
              delicate items to heavy shipments, and we leverage our extensive
              network to optimize every aspect of the transport process. With a
              commitment to reliability, we guarantee that your cargo is handled
              with the utmost care and precision.
            </p>
            <p>
              Our cargo services are built around a customer-centric approach,
              offering tailored solutions to meet your unique needs. Whether you
              require domestic or international transport, Eazymov provides
              seamless integration with our air freight, ocean network, and
              overland network services. Our goal is to streamline your supply
              chain, delivering your cargo on time and in perfect condition.
            </p>
            <p>
              Eazymov is dedicated to staying ahead of industry trends and
              technological advancements in cargo management. We continuously
              enhance our services to address evolving market demands and ensure
              the highest standards of efficiency and security. By choosing
              Eazymov, you partner with a logistics provider that is committed
              to excellence and innovation in cargo transportation.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Cargo Services
            </h1>
            <h2 className="htag mt-5">Introduction to Cargo Services</h2>
            <p className="ptag">
              At Eazymov, we specialize in providing comprehensive cargo
              services tailored to meet the unique needs of our clients. Whether
              you need to move goods across the country or around the world, our
              experienced team ensures that your cargo reaches its destination
              safely and on time.
            </p>
            <h2 className="htag mt-5">Domestic Cargo Solutions</h2>
            <p className="ptag">
              Our domestic cargo services are designed to handle the
              complexities of transporting goods within the country. From small
              parcels to large shipments, Eazymov offers reliable and efficient
              solutions, ensuring that your cargo is handled with care and
              delivered promptly.
            </p>
            <h2 className="htag mt-5">International Cargo Services</h2>
            <p className="ptag">
              Eazymov’s international cargo services provide seamless
              connectivity to major global destinations. With our extensive
              network and expertise in handling customs regulations, we make
              international shipping hassle-free, ensuring your cargo reaches
              its destination without any delays.
            </p>
            <h2 className="htag mt-5">Specialized Cargo Handling</h2>
            <p className="ptag">
              We understand that some cargo requires special attention, whether
              due to its size, nature, or value. Our specialized cargo handling
              services are equipped to manage sensitive, oversized, or
              high-value goods, providing customized solutions that ensure the
              utmost care and security.
            </p>
            <h2 className="htag mt-5">Cargo Tracking and Support</h2>
            <p className="ptag">
              Stay informed every step of the way with our advanced cargo
              tracking system. Eazymov provides real-time updates on the status
              of your shipment, giving you peace of mind and control over your
              cargo's journey.
            </p>
            <h2 className="htag mt-5">
              Why Choose Eazymov for Cargo Services?
            </h2>
            <p className="ptag">
              Choosing Eazymov means partnering with a team that values
              reliability, efficiency, and customer satisfaction. Our commitment
              to quality service, coupled with competitive pricing, makes us the
              preferred choice for cargo services. Whether you’re a small
              business or a large enterprise, we have the expertise and
              resources to meet your cargo needs.
            </p>
          </div>
        </div>
      </div>
      <FirstComp />
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Cargo2;
