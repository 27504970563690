import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/warehousing.jpg";
import "./service.css";
import FirstComp from "../comp/slide";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import LinaerStepper from "../comp/Counter";

const Warehouse = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Warehousing"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>WAREHOUSE</h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Warehouse
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Warehouse</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, our warehousing solutions are designed to offer
              unmatched security and efficiency. Our state-of-the-art facilities
              are equipped with advanced technology to ensure your goods are
              stored safely and managed effectively. With a focus on precision
              and organization, we provide a seamless storage experience that
              supports your logistical needs, from inventory management to order
              fulfillment.
            </p>
            <p>
              Our warehousing services are tailored to meet diverse business
              requirements. Whether you need short-term storage or long-term
              warehousing, Eazymov offers flexible solutions to accommodate
              various types of goods. Our dedicated team ensures that every item
              is handled with care, from arrival to dispatch, ensuring optimal
              conditions and timely processing.
            </p>
            <p>
              As part of our commitment to excellence, we continuously invest in
              our warehousing infrastructure. We integrate the latest in
              inventory management systems and safety protocols to enhance our
              service offerings. By choosing Eazymov, you gain access to a
              reliable partner who prioritizes your cargo's safety and
              efficiency, supporting your business’s growth and operational
              success.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Warehousing Services
            </h1>
            <h2 className="htag mt-5">Introduction to Warehousing</h2>
            <p className="ptag">
              Warehousing is a critical component of the supply chain management
              process, ensuring that goods are stored safely and efficiently
              until they are needed. At Eazymov, our warehousing services are
              designed to provide businesses with flexible and secure storage
              solutions that align with their operational needs.
            </p>
            <h2 className="htag mt-5">
              Importance of Warehousing in Supply Chain
            </h2>
            <p className="ptag">
              Warehousing plays a pivotal role in the supply chain, acting as a
              buffer between production and consumption. It allows businesses to
              maintain inventory levels that can meet customer demand without
              delays. By leveraging our warehousing services, companies can
              optimize their supply chain, reduce costs, and improve delivery
              times.
            </p>

            <h2 className="htag mt-5">
              Types of Warehousing Solutions Offered by Eazymov
            </h2>
            <p className="ptag">
              At Eazymov, we offer a range of warehousing solutions to meet the
              diverse needs of our clients. These include:
              <ul>
                <li>
                  <b>Public Warehousing: </b> Shared facilities that provide
                  cost-effective storage solutions.
                </li>
                <li>
                  <b>Private Warehousing: </b> Dedicated storage space tailored
                  to a specific business's needs.
                </li>
                <li>
                  <b>Bonded Warehousing: </b> Specialized facilities for storing
                  goods under customs control.
                </li>
              </ul>
            </p>
            <h2 className="htag mt-5">Advanced Technology Integration</h2>
            <p className="ptag">
              Our warehousing facilities are equipped with advanced technology
              to ensure the highest level of efficiency and accuracy. We utilize
              inventory management systems, automated retrieval systems, and
              real-time tracking to provide complete visibility and control over
              stored goods.
            </p>
            <h2 className="htag mt-5">Security and Compliance</h2>
            <p className="ptag">
              Eazymov prioritizes the safety and security of your goods. Our
              warehouses are equipped with state-of-the-art security systems,
              including 24/7 surveillance, controlled access, and fire
              prevention measures. We also adhere to all regulatory requirements
              to ensure compliance with industry standards.
            </p>
            <h2 className="htag mt-5">Customized Warehousing Solutions</h2>
            <p className="ptag">
              Understanding that every business has unique requirements, Eazymov
              offers customized warehousing solutions. Whether you need
              short-term storage during peak seasons or long-term warehousing as
              part of your supply chain strategy, our team will work with you to
              develop a solution that fits your needs.
            </p>
            <h2 className="htag mt-5">Conclusion</h2>
            <p className="ptag">
              Eazymov’s warehousing services are designed to provide businesses
              with reliable, secure, and flexible storage solutions. By choosing
              our warehousing services, you are ensuring that your goods are
              handled with care and precision, contributing to the overall
              success of your supply chain.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid py-5">
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-lg-3 col-md-12 ">
                <div className="p-3">
                  <div
                    className="row"
                    style={{
                      boxShadow: "2px 2px 10px lightgray",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="col-sm-2">
                      <img
                        src={e.img}
                        alt="img"
                        style={{ height: "50px", width: "50px" }}
                      />
                    </div>
                    <div className="col-sm-10">
                      <h4
                        style={{
                          fontSize: "18",
                          fontWeight: "700",
                          paddingTop: "6px",
                        }}
                      >
                        {e.head}
                      </h4>
                      <p style={{ fontSize: "14px" }}>{e.text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div> */}
      <FirstComp />
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warehouse;
