import React, { useState } from "react";
import axios from "axios";

const RequestaQuote = () => {
  const [selectedService, setSelectedService] = useState("");
  const [subServices, setSubServices] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    subService: "",
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const services = {
    WAREHOUSES: [
      "Househole Storage",
      "Business Storage",
      "commercial Warehouse for Rent",
      "Household Warehouse for Rent",
    ],
    CARGO: [
      "Air Cargo",
      "Rail Cargo",
      "Road Cargo",
      "Sea International Cargo",
      "Air International Cargo",
    ],
    "HIRE-TRUCK-TEMPO": [
      "Chota Hathi",
      "Tata Ace And Chota Hathi",
      "Ape Xtra (Mahindra)",
      "Mahindra Porter 600",
      "Mahindra Porter 100",
      "Tata 407",
      "Tata Ace Open",
      "Mahindra PickUp",
      "Eicher Truck",
      "Mini Truck",
    ],
    "PET-RELOCATION": ["No Sub-Services"],
    "CAR-TRANSPORTATION": [
      "Luxury Car & Bike Shifting Services",
      "Car Packers & Movers Services",
      "Bike Transportation Services",
      "SUV Car Service",
    ],
    "BIKE-TRANSPORTATION": ["No Sub-Services"],
    "INTERNATIONAL-MOVERS": [
      "Packers & Movers",
      "Househole+Car",
      "Household+Bike",
      "Car-Transportation",
      "Bike-Transportation",
      "Furniture Shifting",
      "Pet-Relocation",
    ],
    "PACKERS & MOVERS": [
      "Home Shifting Services",
      "Office Shifting Services",
      "Local Shifting Services",
      "Furniture Shifting Services",
      "Bed Shifting Services",
      "TV Shifting Services",
      "Sofa Shifting Services",
      "Fridge Shifting Services",
    ],
    TRANSPORT: ["Open Truck", "Small Truck", "Container"],
  };

  const handleServiceChange = (e) => {
    const selected = e.target.value;
    setSelectedService(selected);
    setSubServices(services[selected] || []);
    setFormData({ ...formData, service: selected, subService: "" });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    const { name, email, phone, service, subService } = formData;

    if (!name || !email || !phone || !service || !subService) {
      setError("All fields are required.");
      return;
    }

    try {
      const response = await axios.post(
        "https://eazymov.in:8092/api/quote/newquote",
        formData
      );
      setSuccess("Data sent successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        service: "",
        subService: "",
      });
      setSelectedService("");
      setSubServices([]);
    } catch (error) {
      console.error("Error sending data:", error);
      setError("Error sending data. Please try again.");
    }
  };

  return (
    <div className="container-fluid bg-primary py-3">
      <div className="row text-center">
        <p style={{ fontSize: "22px", fontWeight: "700", color: "white" }}>
          Request a Quote
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12 reqquote">
            <div>
              <input
                placeholder="Your Name"
                type="text"
                className="emailform"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <input
                placeholder="Your Phone"
                type="number"
                className="emailform"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <input
                placeholder="Your Email"
                type="email"
                className="emailform"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <select
                className="selectform"
                onChange={handleServiceChange}
                value={selectedService}
              >
                <option value="">Services</option>
                {Object.keys(services).map((service) => (
                  <option key={service} value={service}>
                    {service}
                  </option>
                ))}
              </select>
            </div>
            {subServices.length > 0 && (
              <div>
                <select
                  className="selectform"
                  name="subService"
                  value={formData.subService}
                  onChange={handleInputChange}
                >
                  <option value="">Select Subservice</option>
                  {subServices.map((subService, index) => (
                    <option key={index} value={subService}>
                      {subService}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
        <div className="row text-center d-flex justify-content-center">
          <button type="submit" className="reqbtn">
            Submit
          </button>
        </div>
      </form>
      {error && <div className="text-danger text-center">{error}</div>}
      {success && <div className="text-success text-center">{success}</div>}
    </div>
  );
};

export default RequestaQuote;
