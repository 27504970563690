import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../../img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";

import {
  TextField,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const UpdatePlan = () => {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    price: "",
    type: "",
    p1: "",
    p2: "",
    p3: "",
    p4: "",
    p5: "",
    p6: "",
    p7: "",
    p8: "",
    p9: "",
    p10: "",
    p11: "",
    p12: "",
    p13: "",
    p14: "",
    p15: "",
    p16: "",
    p17: "",
    p18: "",
    p19: "",
    p20: "",
    p21: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `https://eazymov.in:8092/api/plan/updateplan/${_id}`,
        formData
      );
      // console.log("Plan updated successfully:", response.data);
      // Redirect or give feedback to the user
      toast.success("plan update successfully");
    } catch (error) {
      console.error("There was an error updating the plan!", error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [auth, setAuth] = useAuth();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [showSideNav2, setShowSideNav2] = useState(false);

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const fetchPlan = async () => {
      try {
        const response = await axios.get(
          `https://eazymov.in:8092/api/plan/singleplanbyid/${_id}`
        );
        setFormData(response.data.data); // Ensure this matches the structure of your formData
      } catch (error) {
        console.error("There was an error fetching the plan data!", error);
      }
    };

    fetchPlan();
  }, [_id]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <ToastContainer />
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" style={{ width: "200px" }} />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>{auth?.user?.name}</div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate("/dashboard/admin/profile")}>
                  <FontAwesomeIcon
                    icon={faPerson}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {drawerOpen && !showSideNav2 ? <SideNav /> : <SideNav2 />}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerOpen ? 250 : 50}px)`,
          transition: "width 0.3s",
        }}
      >
        <Toolbar />
        <Box mt={3}>
          <h5>Update Plan</h5>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Price"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            />
            {Array.from({ length: 21 }, (_, i) => (
              <TextField
                key={`p${i + 1}`}
                fullWidth
                margin="normal"
                label={`P${i + 1}`}
                name={`p${i + 1}`}
                value={formData[`p${i + 1}`]}
                onChange={handleChange}
              />
            ))}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePlan;
