import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocation,
  faMessage,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "../pages/home.css";

const Popup2 = (props) => {
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: props.service,
    price: props.price,
    pack: props.pack,
  });

  const InstaRedirect = () => {
    window.open("https://www.instagram.com/eazymov.com_/", "_blank");
  };
  const FaceBookRedirect = () => {
    window.open(
      "https://www.facebook.com/people/Eazymov/61559820655073/",
      "_blank"
    );
  };

  const Youtube = () => {
    window.open("https://www.youtube.com/user/Wix", "_blank");
  };
  const Linkedin = () => {
    window.open("https://www.linkedin.com/", "_blank");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://eazymov.in:8092/api/lead/newLead",
        formData
      );
      toast.success(response?.data?.message);
      handleClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error("Oops! Something went wrong. Please try again later.");
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ToastContainer />

      <button
        className="packbtn"
        onClick={() => {
          handleShow(true);
        }}
      >
        Get Package
      </button>

      <Modal show={show} onHide={handleClose}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#04364A",
            borderRadius: "5px",
          }}
        >
          <div className="modaldesign2">
            <Modal.Body>
              <h5>{props.name}</h5>
              <Form>
                <Form.Group className="my-3" controlId="name">
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="my-4" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="my-4" controlId="phone">
                  <Form.Control
                    type="tel"
                    placeholder="Enter Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </Form.Group>
                {/* <Form.Group className="my-4" controlId="service">
                  <Form.Select
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Choose a Service...</option>
                    {(props.services || []).map((service, index) => (
                      <option key={index} value={service}>
                        {service}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="my-4" controlId="price">
                  <Form.Control
                    type="text"
                    placeholder="Enter price"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="my-4" controlId="pack">
                  <Form.Control
                    type="text"
                    placeholder="Enter pack"
                    name="pack"
                    value={formData.pack}
                    onChange={handleChange}
                    required
                  />
                </Form.Group> */}
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Button
                    onClick={handleSubmit}
                    className="btnsubmit"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button onClick={handleClose} className="btnclose">
                    Close
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </div>
          <div className="modaldesign">
            <h6 style={{ fontSize: "24px", fontWeight: "600" }}>
              Lets Talk On Something{" "}
              <span style={{ color: "#64CCC5" }}>Great</span> Together
            </h6>
            <p style={{ fontWeight: "600" }}>
              <FontAwesomeIcon icon={faMessage} style={{ margin: "0 10px" }} />{" "}
              info@eazymov.com
            </p>
            <p style={{ fontWeight: "600" }}>
              <FontAwesomeIcon icon={faPhoneAlt} style={{ margin: "0 10px" }} />
              7070705528
            </p>
            <p style={{ fontWeight: "600" }}>
              <FontAwesomeIcon icon={faLocation} style={{ margin: "0 10px" }} />{" "}
              Shop No. 20, R.K.Tower, Sector 3, Faridabad 121004
            </p>
            <div
              style={{
                backgroundColor: "#04364A",
                color: "white",
                padding: "10px 0",
                marginTop: "50px",
                width: "100%",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={InstaRedirect}
                icon={faInstagram}
              />
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={FaceBookRedirect}
                icon={faFacebookF}
              />
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={Linkedin}
                icon={faLinkedinIn}
              />
              <FontAwesomeIcon
                style={{ fontSize: "20px", cursor: "pointer" }}
                onClick={Youtube}
                icon={faYoutube}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Popup2;
