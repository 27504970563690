import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css"; // Your custom CSS file
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popup2 from "../comp/Popup2";

const House = () => {
  const [crd1, setCrd1] = useState(true);
  const [crd2, setCrd2] = useState(false);
  const [crd3, setCrd3] = useState(false);
  const [activeButton, setActiveButton] = useState("local");

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const buttonStyle = (buttonType) => ({
    backgroundColor: activeButton === buttonType ? "#0D6EFD" : "lightgray",
    color: activeButton === buttonType ? "white" : "black",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    margin: "0 1px",
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 40px",
        }}
      >
        <div>
          <h4>Household Lead Packages</h4>
        </div>
        <div>
          {" "}
          <button
            onClick={() => {
              setCrd1(true);
              setCrd2(false);
              setCrd3(false);
              handleButtonClick("local");
            }}
            style={buttonStyle("local")}
          >
            Local
          </button>
          <button
            onClick={() => {
              setCrd1(false);
              setCrd2(true);
              setCrd3(false);
              handleButtonClick("domestic");
            }}
            style={buttonStyle("domestic")}
          >
            Domestic
          </button>
          <button
            onClick={() => {
              setCrd1(false);
              setCrd2(false);
              setCrd3(true);
              handleButtonClick("combo");
            }}
            style={buttonStyle("combo")}
          >
            Combo
          </button>
        </div>
      </div>
      {crd1 && <Card1 />}
      {crd2 && <Card2 />}
      {crd3 && <Card3 />}
    </>
  );
};

const Card1 = () => {
  const iconStyles = (icon) => {
    if (icon === faCheck) return { color: "green", fontSize: "20px" };
    if (icon === faXmark) return { color: "red", fontSize: "20px" };
    return {}; // Default style
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const card = [
    {
      type: "Basic",
      price1: "6999/-",
      price2: "6799/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "9499/-",
      price2: "9199/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "13999/-",
      price2: "13499/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "21499/-",
      price2: "20599/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "32999/-",
      price2: "31299/-",
      lead: "250",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  return (
    <>
      <div className="card-slider-container">
        <div className="carousel-head text-dark mb-4"></div>
        <Slider {...settings}>
          {card.map((e) => (
            <div>
              <div className="upperdiv">
                <h5 className="text-center">{e.type} Local HouseHold</h5>
                <p>
                  Best Price :{" "}
                  <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                  <span style={{ fontWeight: "bold" }}>{e.price2}</span> Monthly
                </p>
              </div>
              <div className="lowerdiv">
                {/* <button className="packbtn">Buy Now</button> */}
                <Popup2
                  service="Local HouseHold"
                  price={`${e.price2}`}
                  pack={`${e.type} Local HouseHold`}
                />
                <ul className="packul">
                  <li>Total Leads {e.lead}</li>
                  <li>
                    Verified Seal{" "}
                    <FontAwesomeIcon
                      icon={e.icon1}
                      style={iconStyles(e.icon1)}
                    />
                  </li>
                  <li>
                    Trusted Seal{" "}
                    <FontAwesomeIcon
                      icon={e.icon2}
                      style={iconStyles(e.icon2)}
                    />
                  </li>
                  <li>
                    Banner Lmart{" "}
                    <FontAwesomeIcon
                      icon={e.icon3}
                      style={iconStyles(e.icon3)}
                    />
                  </li>
                  <li>
                    Social Media Profile{" "}
                    <FontAwesomeIcon
                      icon={e.icon4}
                      style={iconStyles(e.icon4)}
                    />
                  </li>
                  <li>
                    Account Manager{" "}
                    <FontAwesomeIcon
                      icon={e.icon5}
                      style={iconStyles(e.icon5)}
                    />
                  </li>
                  <li>
                    Support{" "}
                    <FontAwesomeIcon
                      icon={e.icon6}
                      style={iconStyles(e.icon6)}
                    />
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
const Card2 = () => {
  const iconStyles = (icon) => {
    if (icon === faCheck) return { color: "green", fontSize: "20px" };
    if (icon === faXmark) return { color: "red", fontSize: "20px" };
    return {}; // Default style
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const card = [
    {
      type: "Basic",
      price1: "11499/-",
      price2: "11299/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "16999/-",
      price2: "16599/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "22999/-",
      price2: "22399/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "34799/-",
      price2: "33799/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "54999/-",
      price2: "52399/-",
      lead: "250",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  return (
    <>
      <div className="card-slider-container">
        <div className="carousel-head text-dark mb-4"></div>
        <Slider {...settings}>
          {card.map((e) => (
            <div>
              <div className="upperdiv">
                <h5 className="text-center">{e.type} Domestic HouseHold</h5>
                <p>
                  Best Price :{" "}
                  <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                  <span style={{ fontWeight: "bold" }}>{e.price2}</span> Monthly
                </p>
              </div>
              <div className="lowerdiv">
                <Popup2
                  service="Domestic HouseHold"
                  price={`${e.price2}`}
                  pack={`${e.type} Domestic HouseHold`}
                />
                <ul className="packul">
                  <li>Total Leads {e.lead}</li>
                  <li>
                    Verified Seal{" "}
                    <FontAwesomeIcon
                      icon={e.icon1}
                      style={iconStyles(e.icon1)}
                    />
                  </li>
                  <li>
                    Trusted Seal{" "}
                    <FontAwesomeIcon
                      icon={e.icon2}
                      style={iconStyles(e.icon2)}
                    />
                  </li>
                  <li>
                    Banner Lmart{" "}
                    <FontAwesomeIcon
                      icon={e.icon3}
                      style={iconStyles(e.icon3)}
                    />
                  </li>
                  <li>
                    Social Media Profile{" "}
                    <FontAwesomeIcon
                      icon={e.icon4}
                      style={iconStyles(e.icon4)}
                    />
                  </li>
                  <li>
                    Account Manager{" "}
                    <FontAwesomeIcon
                      icon={e.icon5}
                      style={iconStyles(e.icon5)}
                    />
                  </li>
                  <li>
                    Support{" "}
                    <FontAwesomeIcon
                      icon={e.icon6}
                      style={iconStyles(e.icon6)}
                    />
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
const Card3 = () => {
  const iconStyles = (icon) => {
    if (icon === faCheck) return { color: "green", fontSize: "20px" };
    if (icon === faXmark) return { color: "red", fontSize: "20px" };
    return {}; // Default style
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const card = [
    {
      type: "Basic",
      price1: "9399/-",
      price2: "8999/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "13999/-",
      price2: "13399/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "18999/-",
      price2: "17799/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "28999/-",
      price2: "26999/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "44999/-",
      price2: "41899/-",
      lead: "250",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  return (
    <>
      <div className="card-slider-container">
        <div className="carousel-head text-dark mb-4"></div>
        <Slider {...settings}>
          {card.map((e) => (
            <div>
              <div className="upperdiv">
                <h5 className="text-center">{e.type} Single Item</h5>
                <p>
                  Best Price :{" "}
                  <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                  <span style={{ fontWeight: "bold" }}>{e.price2}</span> Monthly
                </p>
              </div>
              <div className="lowerdiv">
                <Popup2
                  service="Combo HouseHold"
                  price={`${e.price2}`}
                  pack={`${e.type} Combo HouseHold`}
                />
                <ul className="packul">
                  <li>Total Leads {e.lead}</li>
                  <li>
                    Verified Seal{" "}
                    <FontAwesomeIcon
                      icon={e.icon1}
                      style={iconStyles(e.icon1)}
                    />
                  </li>
                  <li>
                    Trusted Seal{" "}
                    <FontAwesomeIcon
                      icon={e.icon2}
                      style={iconStyles(e.icon2)}
                    />
                  </li>
                  <li>
                    Banner Lmart{" "}
                    <FontAwesomeIcon
                      icon={e.icon3}
                      style={iconStyles(e.icon3)}
                    />
                  </li>
                  <li>
                    Social Media Profile{" "}
                    <FontAwesomeIcon
                      icon={e.icon4}
                      style={iconStyles(e.icon4)}
                    />
                  </li>
                  <li>
                    Account Manager{" "}
                    <FontAwesomeIcon
                      icon={e.icon5}
                      style={iconStyles(e.icon5)}
                    />
                  </li>
                  <li>
                    Support{" "}
                    <FontAwesomeIcon
                      icon={e.icon6}
                      style={iconStyles(e.icon6)}
                    />
                  </li>
                </ul>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default House;
