import React, { useEffect } from "react";
import Layout from "../comp/Layout";
import bg from "../img/about-page-bg.jpg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css"; // Your custom CSS file
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getcallback from "../img/getcallback.webp";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import House from "./Householdlead";
import Popup2 from "../comp/Popup2";

const Buylead = () => {
  const iconStyles = (icon) => {
    if (icon === faCheck) return { color: "green", fontSize: "20px" };
    if (icon === faXmark) return { color: "red", fontSize: "20px" };
    return {}; // Default style
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const card = [
    {
      type: "Basic",
      price1: "5999/-",
      price2: "5499/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "8599/-",
      price2: "7899/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "11999/-",
      price2: "10399/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "17999/-",
      price2: "15399/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "24999/-",
      price2: "19999/-",
      lead: "200",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  const card2 = [
    {
      type: "Basic",
      price1: "4499/-",
      price2: "3999/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "6399/-",
      price2: "5699/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "8499/-",
      price2: "7399/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "11999/-",
      price2: "10699/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  const card3 = [
    {
      type: "Basic",
      price1: "6499/-",
      price2: "5999/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "12999/-",
      price2: "11899/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "18999/-",
      price2: "17399/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "25999/-",
      price2: "23699/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "33999/-",
      price2: "29499/-",
      lead: "200",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];

  const card4 = [
    {
      type: "Basic",
      price1: "7999/-",
      price2: "7499/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "11599/-",
      price2: "10750/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "premium",
      price1: "15999/-",
      price2: "14900/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "25999/-",
      price2: "23699/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "33999/-",
      price2: "29499/-",
      lead: "200",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  const card5 = [
    {
      type: "Basic",
      price1: "8999/-",
      price2: "8699/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "13599/-",
      price2: "12699/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },

    {
      type: "Gold",
      price1: "26999/-",
      price2: "24999/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "41999/-",
      price2: "39399/-",
      lead: "250",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  const card6 = [
    {
      type: "Basic",
      price1: "7999/-",
      price2: "7599/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "11999/-",
      price2: "11299/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "16999/-",
      price2: "15999/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "23999/-",
      price2: "22399/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  const card7 = [
    {
      type: "Basic",
      price1: "6999/-",
      price2: "6799/-",
      lead: "50",
      icon1: faXmark,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Value",
      price1: "10599/-",
      price2: "9999/-",
      lead: "75",
      icon1: faCheck,
      icon2: faXmark,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Silver",
      price1: "13599/-",
      price2: "12499/-",
      lead: "100",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faXmark,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Gold",
      price1: "20999/-",
      price2: "19399/-",
      lead: "150",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faXmark,
      icon5: faCheck,
      icon6: faCheck,
    },
    {
      type: "Platinum",
      price1: "31999/-",
      price2: "29699/-",
      lead: "250",
      icon1: faCheck,
      icon2: faCheck,
      icon3: faCheck,
      icon4: faCheck,
      icon5: faCheck,
      icon6: faCheck,
    },
  ];
  return (
    <>
      <Layout>
        <div
          className="container-fluid p-5"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="row text-light">
            <div className="col-sm-12">
              <h4 style={{ fontSize: "36px", fontWeight: "700" }}>BUY LEADS</h4>
              <div
                style={{
                  height: "3px",
                  width: "80px",
                  backgroundColor: "white",
                  border: "none",
                  margin: "20px 0",
                }}
              ></div>
              <p style={{ fontSize: "16px", padding: "20px 0" }}>
                You Are Here : <Link to="/">Home</Link> {">"} Buy Leads
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-s-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <button
                  style={{
                    backgroundColor: "white",
                    height: "50px",
                    border: "5px solid #7b1fa2",
                  }}
                >
                  Choose the type of package
                </button>
                <div class="dropdownnnn">
                  Select Package Type
                  <ul class="dropdownnnn-menu">
                    <li>
                      <a href="#single">Single Item</a>
                    </li>
                    <li>
                      <a href="#bike">Bike Transport</a>
                    </li>
                    <li>
                      <a href="#car">Car Transport</a>
                    </li>
                    <li>
                      <a href="#cargo">Cargo</a>
                    </li>
                    <li>
                      <a href="#household">HouseHold</a>
                    </li>
                    <li>
                      <a href="#hhb">Bike+HouseHold</a>
                    </li>
                    <li>
                      <a href="#hhc">Car+HouseHold</a>
                    </li>
                    <li>
                      <a href="#combo">Combo ALl Type</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-slider-container">
          <div className="carousel-head text-dark mb-4">
            <h4 id="single"> Single Item Lead Packages</h4>
          </div>
          <Slider {...settings}>
            {card.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">{e.type} Single Item</h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  {/* <Popup2 name={`${e.type} Single Item`} /> */}
                  <Popup2
                    service="Single Item Lead"
                    price={`${e.price2}`}
                    pack={`${e.type} Single Item`}
                  />

                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div
          className="card-slider-container"
          style={{ backgroundColor: "#EAF7FF" }}
        >
          <div className="carousel-head text-dark mb-4">
            <h4 id="bike">Bike Transport Lead Packages</h4>
          </div>
          <Slider {...settings}>
            {card2.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">{e.type} Bike</h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  <Popup2
                    service="Bike Transport Lead"
                    price={`${e.price2}`}
                    pack={`${e.type} Bike`}
                  />
                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="card-slider-container">
          <div className="carousel-head text-dark mb-4">
            <h4 id="car"> Car Transport Lead Packages</h4>
          </div>
          <Slider {...settings}>
            {card3.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">{e.type} Car</h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  <Popup2
                    service="Car Transport Lead"
                    price={`${e.price2}`}
                    pack={`${e.type} Car`}
                  />
                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div
          className="card-slider-container"
          style={{ backgroundColor: "#EAF7FF" }}
        >
          <div className="carousel-head text-dark mb-4">
            <h4 id="cargo">Cargo Lead Packages</h4>
          </div>
          <Slider {...settings}>
            {card4.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">{e.type} Cargo Domestic Air</h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  <Popup2
                    service="Cargo Lead"
                    price={`${e.price2}`}
                    pack={`${e.type} Cargo Domestic Air`}
                  />
                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {/* fhehfiu */}
        <div id="household">
          <House />
        </div>
        <div
          className="card-slider-container"
          style={{ backgroundColor: "#EAF7FF" }}
        >
          <div className="carousel-head text-dark mb-4">
            <h4 id="hhc">Car + Household Lead Packages</h4>
          </div>
          <Slider {...settings}>
            {card5.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">{e.type} HouseHold + Car</h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  <Popup2
                    service="HouseHold + Car Leads"
                    price={`${e.price2}`}
                    pack={`${e.type} Household + Car`}
                  />
                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="card-slider-container">
          <div className="carousel-head text-dark mb-4">
            <h4 id="hhb">Bike + Household Lead Packages</h4>
          </div>
          <Slider {...settings}>
            {card6.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">
                    {e.type} Domestic HouseHold + Bike
                  </h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  <Popup2
                    service="Domestic HouseHold + Bike"
                    price={`${e.price2}`}
                    pack={`${e.type} HouseHold + Bike`}
                  />
                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div
          className="card-slider-container"
          style={{ backgroundColor: "#EAF7FF" }}
        >
          <div className="carousel-head text-dark mb-4">
            <h4 id="combo">Combo All Type</h4>
          </div>
          <Slider {...settings}>
            {card7.map((e) => (
              <div>
                <div className="upperdiv">
                  <h5 className="text-center">{e.type} Combo All Type</h5>
                  <p>
                    Best Price :{" "}
                    <strike style={{ fontWeight: "bold" }}>{e.price1}</strike>{" "}
                    <span style={{ fontWeight: "bold" }}>{e.price2}</span>{" "}
                    Monthly
                  </p>
                </div>
                <div className="lowerdiv">
                  {/* <button className="packbtn">Buy Now</button> */}
                  <Popup2
                    service="Combo All Type"
                    price={`${e.price2}`}
                    pack={`${e.type} Combo All Type`}
                  />
                  <ul className="packul">
                    <li>Total Leads {e.lead}</li>
                    <li>
                      Verified Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon1}
                        style={iconStyles(e.icon1)}
                      />
                    </li>
                    <li>
                      Trusted Seal{" "}
                      <FontAwesomeIcon
                        icon={e.icon2}
                        style={iconStyles(e.icon2)}
                      />
                    </li>
                    <li>
                      Banner Lmart{" "}
                      <FontAwesomeIcon
                        icon={e.icon3}
                        style={iconStyles(e.icon3)}
                      />
                    </li>
                    <li>
                      Social Media Profile{" "}
                      <FontAwesomeIcon
                        icon={e.icon4}
                        style={iconStyles(e.icon4)}
                      />
                    </li>
                    <li>
                      Account Manager{" "}
                      <FontAwesomeIcon
                        icon={e.icon5}
                        style={iconStyles(e.icon5)}
                      />
                    </li>
                    <li>
                      Support{" "}
                      <FontAwesomeIcon
                        icon={e.icon6}
                        style={iconStyles(e.icon6)}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div
              className="col-md-6 col-sm-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img
                alt="img"
                src={getcallback}
                style={{ height: "300px", width: "300px" }}
              />
            </div>
            <div className="col-md-6 col-sm-12 text-center pt-5">
              <h4 className="pt-5">Still Confused Which Package to Choose</h4>
              <p>
                Connect with Eazymov's dedicated support team for personalized
                solutions.
              </p>
              <h4>
                <span style={{ color: "red" }}>Call : </span>{" "}
                <span style={{ color: "green" }}>70-70-70-55-28</span>
              </h4>
            </div>
          </div>
        </div>
        <Steps />
        <AccordionExpandIcon />
      </Layout>
    </>
  );
};

export default Buylead;
