import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faLock,
  faPerson,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../../img/logo.png";
import axios from "axios";
import SideNav from "./SideNav";
import SideNav2 from "./Sidenav2";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
  TextField,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Select,
} from "@mui/material";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";

const AllQueries = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [quoteStartDate, setQuoteStartDate] = useState("");
  const [quoteEndDate, setQuoteEndDate] = useState("");
  const [status] = useState([
    "Not Process",
    "Processing",
    "Completed",
    "Cancel",
  ]);

  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [queries, setQueries] = useState([]);
  const [landing, setLanding] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSideNav2, setShowSideNav2] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  const fetchQueries = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "https://eazymov.in:8092/api/query/getallquery"
      );
      setQueries(data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchLanding = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        "https://eazymov.in:8092/api/quote/allquote"
      );
      setLanding(data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeLandingStatus = async (id, value) => {
    try {
      await axios.put(`https://eazymov.in:8092/api/quote/updatestatus/${id}`, {
        status: value,
      });
      fetchLanding();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (id, value) => {
    try {
      await axios.put(`https://eazymov.in:8092/api/query/updatestatus/${id}`, {
        status: value,
      });
      fetchQueries();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchQueries();
    fetchLanding();
  }, [isLoading]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowSideNav2(true);
        setDrawerOpen(false);
      } else {
        setShowSideNav2(false);
        setDrawerOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleOnExcel = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(queries);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  };

  const handleOnExcel2 = () => {
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(landing);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
    XLSX.writeFile(wb, "MyExcel.xlsx");
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = String(date.getFullYear()).slice(-2);
  //   return `${day}/${month}/${year}`;
  // };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredQuotes = landing.filter((quote) => {
    if (!quoteStartDate && !quoteEndDate) return true;
    const quoteDate = new Date(quote.createdAt);
    const start = quoteStartDate
      ? new Date(quoteStartDate)
      : new Date(-8640000000000000);
    const end = quoteEndDate
      ? new Date(quoteEndDate)
      : new Date(8640000000000000);
    return quoteDate >= start && quoteDate <= end;
  });

  const totalPages = Math.ceil(filteredQuotes.length / itemsPerPage);
  const paginatedQuotes = filteredQuotes.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const ordersPerPage = 10;

  const filteredOrders = queries.filter((query) => {
    if (!startDate && !endDate) return true;
    const queryDate = new Date(query.createdAt);
    const start = startDate ? new Date(startDate) : new Date(-8640000000000000);
    const end = endDate ? new Date(endDate) : new Date(8640000000000000);
    return queryDate >= start && queryDate <= end;
  });

  const totalOrderPages = Math.ceil(filteredOrders.length / ordersPerPage);
  const paginatedOrders = filteredOrders.slice(
    (currentOrderPage - 1) * ordersPerPage,
    currentOrderPage * ordersPerPage
  );

  const handleOrderPageChange = (event, value) => {
    setCurrentOrderPage(value);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant="h6" noWrap>
            <img src={logo} alt="logo" className="dashlogo" />
          </Typography>
          <div className="ms-auto">
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                <div className="d-flex justify-content-around align-items-center gap-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faPerson}
                      style={{ fontSize: "22px" }}
                    />
                  </div>
                  <div>{auth?.user?.name}</div>
                </div>
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Change Password
                </MenuItem>
                <MenuItem onClick={logout}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ marginRight: "10px" }}
                  />{" "}
                  Sign Out
                </MenuItem>
              </Menu>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? 250 : 50,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 250 : 50,
            boxSizing: "border-box",
            transition: "width 0.3s",
            paddingTop: "100px",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          {showSideNav2 ? <SideNav2 /> : <SideNav />}
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: { sm: `calc(100% - ${drawerOpen ? 250 : 50}px)` },
          transition: "width 0.3s",
        }}
      >
        <Toolbar />
        <Box className="container">
          <Box className="row">
            <Box className="col-sm-12 mb-3">
              <Typography
                variant="h5"
                className="mb-4"
                style={{ fontWeight: "bold" }}
              >
                All Quotes
              </Typography>
              <Box className="d-flex mb-3">
                <TextField
                  id="start-date"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="me-3"
                />
                <TextField
                  id="end-date"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnExcel}
              >
                Export Orders to Excel
              </Button>
              <TableContainer component={Paper} className="mt-4">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No.</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Phone</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">to</TableCell>
                      <TableCell align="center">from</TableCell>
                      <TableCell align="center">service</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedOrders.map((query, index) => (
                      <TableRow key={query._id}>
                        <TableCell align="center">
                          {(currentOrderPage - 1) * ordersPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">{query.date}</TableCell>
                        <TableCell align="center">{query.name}</TableCell>
                        <TableCell align="center">{query.phone}</TableCell>
                        <TableCell align="center">{query.email}</TableCell>
                        <TableCell align="center">{query.to}</TableCell>
                        <TableCell align="center">{query.from}</TableCell>
                        <TableCell align="center">{query.service}</TableCell>

                        <TableCell align="center">
                          <Select
                            value={query.status}
                            onChange={(e) =>
                              handleChange(query._id, e.target.value)
                            }
                          >
                            {status.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={totalOrderPages}
                page={currentOrderPage}
                onChange={handleOrderPageChange}
                color="primary"
                className="mt-3"
              />
            </Box>
            <Box className="col-sm-12">
              <Typography
                variant="h5"
                className="mb-4"
                style={{ fontWeight: "bold" }}
              >
                All Orders
              </Typography>
              <Box className="d-flex mb-3">
                <TextField
                  id="quote-start-date"
                  label="Start Date"
                  type="date"
                  value={quoteStartDate}
                  onChange={(e) => setQuoteStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="me-3"
                />
                <TextField
                  id="quote-end-date"
                  label="End Date"
                  type="date"
                  value={quoteEndDate}
                  onChange={(e) => setQuoteEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnExcel2}
              >
                Export Quotes to Excel
              </Button>
              <TableContainer component={Paper} className="mt-4">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No.</TableCell>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">Name</TableCell>
                      <TableCell align="center">Email</TableCell>
                      <TableCell align="center">Phone</TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Message</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedQuotes.map((quote, index) => (
                      <TableRow key={quote._id}>
                        <TableCell align="center">
                          {(currentPage - 1) * itemsPerPage + index + 1}
                        </TableCell>
                        <TableCell align="center">{quote.date}</TableCell>
                        <TableCell align="center">{quote.name}</TableCell>
                        <TableCell align="center">{quote.email}</TableCell>
                        <TableCell align="center">{quote.phone}</TableCell>
                        <TableCell align="center">{quote.select}</TableCell>
                        <TableCell align="center">{quote.message}</TableCell>

                        <TableCell align="center">
                          <Select
                            value={quote.status}
                            onChange={(e) =>
                              handleChangeLandingStatus(
                                quote._id,
                                e.target.value
                              )
                            }
                          >
                            {status.map((s) => (
                              <MenuItem key={s} value={s}>
                                {s}
                              </MenuItem>
                            ))}
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                className="mt-3"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllQueries;
