import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/slider2.5.jpg";
import "./service.css";
import FirstComp from "../comp/slide";
import Steps from "../comp/Steps";
import AccordionExpandIcon from "../comp/Faq";
import LinaerStepper from "../comp/Counter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(from, to, oneBHK, twoBHK, threeBHK, fewItems) {
  return { from, to, oneBHK, twoBHK, threeBHK, fewItems };
}

const rows = [
  createData(
    "Delhi",
    "Chennai",
    "22000 - 27000",
    "28000 - 36000",
    "40000 - 50000",
    "15000-20000"
  ),
  createData(
    "Mumbai",
    "Kolkata",
    "10000 - 20000",
    "15000 - 25000",
    "19000 - 35000",
    "8000 - 18000"
  ),
  createData(
    "Kolkata",
    "Ahmedabad",
    "8000 - 15000",
    "12000 - 18000",
    "15000 - 24000",
    "5000 - 14000"
  ),
  createData(
    "Chennai",
    "Chandigarh",
    "5000 - 8000",
    "7000 - 10000",
    "9000 - 14000",
    "3000 - 7000"
  ),
  createData(
    "Jaipur",
    "Vadodara",
    "8000 - 15000",
    "13000 - 20000",
    "14000 - 24000",
    "7000 - 15000"
  ),
  createData(
    "Gurgaon",
    "Pune",
    "10000 - 17000",
    "13000 - 24000",
    "16000 - 25000",
    "8000 - 15000"
  ),
  createData(
    "Surat",
    "Lucknow",
    "6000 - 9000",
    "7000 - 12000",
    "15000 - 22000",
    "5000 - 10000"
  ),
  createData(
    "Pune",
    "Navi Mumbai",
    "10000 - 18000",
    "15000 - 22000",
    "18000 - 25000",
    "7000 - 15000"
  ),
  createData(
    "Ahmedabad",
    "Surat",
    "8000 - 16000",
    "14000 - 20000",
    "15000 - 25000",
    "7000 - 15000"
  ),
  createData(
    "Chandigarh",
    "Jaipur",
    "5000 - 8000",
    "7000 - 10000",
    "10000 - 15000",
    "4000 - 7000"
  ),
  createData(
    "Vadodara",
    "Gurgaon",
    "4000 - 7000",
    "6000 - 9000",
    "9000 - 14000",
    "3000 - 7000"
  ),
];

function createData2(movingType, packingCost, totalCost) {
  return { movingType, packingCost, totalCost };
}

const rows2 = [
  createData2("1 BHK", "1000-2500", "2500-8000"),
  createData2("2 BHK", "2000-3500", "5500-14000"),
  createData2("3 BHK", "2000-4000", "6000-20000"),
];

const Packers = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"Packers & Movers"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              Packers & Movers{" "}
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} Packers & Movers
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>Packers & Movers</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, we provide top-notch Packers & Movers solutions
              tailored to your specific needs. Our facilities are equipped with
              the latest technology to ensure the safe and efficient Packers &
              Movers of your goods. Whether you require short-term or long-term
              Packers & Movers, we offer flexible options to accommodate various
              types of inventory, ensuring that your items remain in optimal
              condition.
            </p>
            <p>
              Our Packers & Movers services are designed to integrate seamlessly
              with your logistics operations. With robust inventory management
              systems and stringent security measures, Eazymov ensures that your
              goods are monitored and managed effectively. Our team of
              professionals is dedicated to maintaining the highest standards of
              cleanliness and organization, providing you with peace of mind.
            </p>
            <p>
              Eazymov is committed to enhancing your Packers & Movers experience
              through continuous improvement and innovation. We invest in our
              infrastructure and processes to keep pace with industry
              advancements and meet the evolving needs of our clients. By
              choosing Eazymov for your Packers & Movers needs, you benefit from
              a reliable partner focused on efficiency, security, and
              exceptional service.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              Packers & Movers Services
            </h1>
            <h2 className="htag mt-5">
              Introduction to Packers & Movers Services
            </h2>
            <p className="ptag">
              At Eazymov, we understand that moving can be a stressful
              experience. Whether you're relocating your home, office, or
              commercial space, our Packers & Movers services are designed to
              make the transition as smooth and hassle-free as possible.
            </p>
            <h2 className="htag mt-5">Why Choose Eazymov Packers & Movers?</h2>
            <p className="ptag">
              Our team is composed of trained professionals who are experts in
              packing, loading, transporting, and unloading your valuable
              possessions with the utmost care. We use high-quality packing
              materials and advanced techniques to ensure that your items reach
              their destination safely.
            </p>
            <h2 className="htag mt-5">Comprehensive Range of Services</h2>
            <p className="ptag">
              We offer a wide range of services to cater to your specific moving
              needs:
              <ul>
                <li>
                  <b>Residential Moving:</b> Safe and efficient packing and
                  moving of household items.
                </li>
                <li>
                  <b>Office Relocation:</b> Seamless transition of your office
                  setup to a new location.
                </li>
                <li>
                  <b>Commercial Moving:</b> Expertise in handling large-scale
                  commercial relocations.
                </li>
                <li>
                  <b>Vehicle Transportation:</b> Secure transport of cars,
                  bikes, and other vehicles.
                </li>
              </ul>
            </p>
            <h2 className="htag mt-5">Customized Moving Solutions</h2>
            <p className="ptag">
              Every move is unique, and so are our solutions. We provide
              tailored moving plans that fit your specific requirements, whether
              you're moving within the city or across the country.
            </p>
            <h2 className="htag mt-5">Safety and Security</h2>
            <p className="ptag">
              At Eazymov, the safety of your belongings is our top priority. Our
              team follows strict protocols and uses high-grade materials to
              ensure that everything is packed securely. We also offer insurance
              options for added peace of mind.
            </p>
            <h2 className="htag mt-5">Affordable Pricing</h2>
            <p className="ptag">
              We believe in providing top-notch services at competitive rates.
              Our transparent pricing policy ensures that you get the best value
              for your money without any hidden charges.
            </p>
            <h2 className="htag mt-5">Customer Support</h2>
            <p className="ptag">
              Our dedicated customer support team is available 24/7 to assist
              you with any queries or concerns during your move. We are
              committed to providing a seamless experience from start to finish
            </p>
            <h2 className="htag mt-5">How to Get Started?</h2>
            <p className="ptag">
              Getting started with Eazymov Packers & Movers is easy. Simply
              contact us for a free quote, and we'll take care of the rest. Our
              team will guide you through the process, ensuring a smooth and
              efficient move.
            </p>
          </div>
        </div>
      </div>

      <FirstComp />

      <div className="container my-5">
        <h2 className="htag my-3">India Wise Packers and Movers Rate Chart</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#0B60A9" }}>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  From
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  To
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  1 BHK FLAT
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  2 BHK FLAT
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  3 BHK FLAT
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontWeight: "bolder", color: "white" }}
                >
                  FEW HOME ITEMS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={`${row.from}-${row.to}`}>
                  <TableCell align="center">{row.from}</TableCell>
                  <TableCell align="center">{row.to}</TableCell>
                  <TableCell align="center">{row.oneBHK}</TableCell>
                  <TableCell align="center">{row.twoBHK}</TableCell>
                  <TableCell align="center">{row.threeBHK}</TableCell>
                  <TableCell align="center">{row.fewItems}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="container my-5">
        <h2 className="htag my-3">
          How to Calculate Packers and Movers Charges Per Km?
        </h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#0B60A9" }}>
                <TableCell align="center" style={{ color: "white" }}>
                  <strong>Moving Type</strong>
                </TableCell>
                <TableCell align="center" style={{ color: "white" }}>
                  <strong>Packing Cost (Rs.)</strong>
                </TableCell>
                <TableCell align="center" style={{ color: "white" }}>
                  <strong>Total Cost of Shifting (Rs.)</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows2.map((row) => (
                <TableRow key={row.movingType}>
                  <TableCell align="center">{row.movingType}</TableCell>
                  <TableCell align="center">{row.packingCost}</TableCell>
                  <TableCell align="center">{row.totalCost}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Packers;
