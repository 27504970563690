import { faClock, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "./headfoot.css";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons/faMailBulk";
import logo from "../img/logo.png";
import { useAuth } from "./auth/Auth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Header1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   const navigate = useNavigate();

  const handleDialerClick = () => {
    const phoneNumber = "7070705528";

    const dialerUrl = `tel:${phoneNumber}`;

    window.open(dialerUrl, "_self");
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://eazymov.in:8092/api/user/login", {
        email,
        password,
      });
      if (res?.data?.success) {
        toast.success("login Successfully");
        setAuth({
          ...auth,
          user: res?.data?.user,
          token: res?.data?.token,
        });
        localStorage.setItem("auth", JSON.stringify(res?.data));
        navigate("/dashboard/admin");
        window.location.reload();
      } else {
        toast.error("Check Login Details");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const logout = () => {
    setAuth({
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };
  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <div>
            <img
              className="footerlogo"
              onClick={() => {
                navigate("/");
              }}
              style={{ cursor: "pointer", height: "70px" }}
              src={logo}
              alt="logo"
            />
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <div
                className="nav-item px-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div style={{ backgroundColor: "#0B60A9", padding: "10px" }}>
                  {" "}
                  <FontAwesomeIcon className="headicon" icon={faClock} />
                </div>

                <div>
                  <p className="mt-3" style={{ fontSize: "16px" }}>
                    Opening Hours <br />
                    <span style={{ fontWeight: "600" }}>
                      Mon - Sat: 09.00 - 17.00
                    </span>
                  </p>
                </div>
              </div>

              <div
                className="nav-item px-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div style={{ backgroundColor: "#0B60A9", padding: "10px" }}>
                  {" "}
                  <FontAwesomeIcon className="headicon" icon={faPhone} />
                </div>

                <div onClick={handleDialerClick}>
                  <p className="mt-3" style={{ fontSize: "16px" }}>
                    Call Us
                    <br />
                    <span style={{ fontWeight: "600" }}>707-070-5528</span>
                  </p>
                </div>
              </div>
              <div
                className="nav-item px-3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div style={{ backgroundColor: "#0B60A9", padding: "10px" }}>
                  {" "}
                  <FontAwesomeIcon className="headicon" icon={faMailBulk} />
                </div>

                <div>
                  <p className="mt-3" style={{ fontSize: "16px" }}>
                    Email Us
                    <br />
                    <span style={{ fontWeight: "600" }}>info@eazymov.com</span>
                  </p>
                </div>
                <div>
                  {!auth?.user ? (
                    <li style={{ listStyle: "none" }} className="topnav1">
                      <div>
                        <div>
                          <p
                            type="button"
                            className="loginbtn"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            Login
                          </p>
                          {/* Modal */}
                          <div
                            className="modal fade text-center"
                            style={{ backgroundColor: "transparent" }}
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog"
                              style={{ backgroundColor: "transparent" }}
                            >
                              <div
                                className="modal-content"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <div
                                  className="modal-body text-dark"
                                  style={{ backgroundColor: "transparent" }}
                                >
                                  <div
                                    className="container-fluid"
                                    style={{ backgroundColor: "transparent" }}
                                  >
                                    <form action="" className="form">
                                      <input
                                        required
                                        className="input"
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="E-mail"
                                        value={email}
                                        onChange={(e) =>
                                          setEmail(e.target.value)
                                        }
                                      />
                                      <input
                                        required
                                        className="input"
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) =>
                                          setPassword(e.target.value)
                                        }
                                      />
                                      <span className="forgot-password">
                                        <a href="/">Forgot Password ?</a>
                                      </span>
                                      <input
                                        className="login-button"
                                        type="submit"
                                        value="Sign In"
                                        onClick={submit}
                                      />
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li
                      className="topnav1 dropdown"
                      style={{ listStyle: "none" }}
                    >
                      <div className="dropdown" style={{ zIndex: "1000" }}>
                        <Link
                          className="nav-link dropdown-toggle"
                          role="button"
                          id="navbarDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {auth?.user?.name}
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                          style={{ zIndex: "100000" }}
                        >
                          <li>
                            <NavLink
                              to={"/dashboard/admin"}
                              className="dropdown-item  bg-light"
                            >
                              Dashboard
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              className="dropdown-item bg-light text-dark"
                              onClick={logout}
                              to="/"
                            >
                              Logout
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <div
        className="container-fluid text-light"
        style={{ backgroundColor: "#0B60A9" }}
      >
        <div className="row px-5">
          <div className="col-sm-10 py-2">
            <Link className="headlink" to="/">
              HOME
            </Link>
            <Link className="headlink" to="/about-us">
              ABOUT US
            </Link>
            {/* <Link className="headlink" to="/services">
              SERVICES
            </Link> */}
            <div className="dropdown">
              <Link className="dropbtn headlink" to="/services">
                SERVICES
              </Link>
              <div className="dropdown-content">
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/packers-movers">Packers & Movers</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/car-transportation">Car Transportation</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/hire-truck-tempo">Hire Truck Tempo</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/cargo">Cargo</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/transport">Transport</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/warehousing">Warehouse</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/bike-transportation">Bike Transportation</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/international-movers">International Movers</Link>
                </a>
                <a style={{ borderBottom: "1px solid gray" }}>
                  <Link to="/pet-relocation">Pet Relocation</Link>
                </a>
              </div>
            </div>
            <Link className="headlink">BLOG </Link>
            <Link className="headlink" to="/contact-us">
              CONTACT
            </Link>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    </>
  );
};

export default Header1;
