import React from "react";
import Layout from "../comp/Layout";
import ResponsiveCarousel from "./Carousel1.js";
import "./home.css";
import CardSlider from "./Cardslide2.js";
import Card3 from "./Card3.js";
// import ServiceCal from "./ServiceCal.js";
import Foter2comp from "../comp/Footercomp2.js";
import LinaerStepper from "../comp/Counter.js";
import AccordionExpandIcon from "../comp/Faq.js";
import RequestaQuote from "../comp/Requesta Quote.js";
import Servicepro from "../comp/Servicepro.js";
import Modal from "../comp/Popup.js";
import Customer from "../comp/Customer.js";

const Homepage = () => {
  const card = [
    {
      img: "/img/customer.png",
      head: "2400 +",
      text: "DAILY HAPPY CUSTOMERS",
    },
    {
      img: "/img/service.png",
      head: "1200 +",
      text: "VERIFIED SERVICE PROVIDERS",
    },
    {
      img: "/img/rating.png",
      head: "4.5/7",
      text: "AVERAGE RATING",
    },
  ];

  return (
    <Layout>
      <Modal />
      <ResponsiveCarousel />
      <div className="container-fluid">
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 p-3 text-center">
                <div>
                  <img src={e.img} alt="img" className="cardimg" />
                  <h1 className="cardhtag">{e.head}</h1>
                  <p className="cardptag">{e.text}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <RequestaQuote />
      <div className="homeslider2">
        <CardSlider />
      </div>
      <Card3 />
      <RequestaQuote />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              {" "}
              Why EAZYMOV
            </h1>
            <h2 className="htag mt-5">1. Our Commitment to Trust</h2>
            <p className="ptag">
              At Eazymov, trust is the cornerstone of our business. We
              understand that moving, whether locally or internationally, is a
              significant event in your life, and you need a reliable partner to
              make the process smooth and hassle-free. Our team is dedicated to
              ensuring transparency and honesty in every step of your move, from
              the initial consultation to the final delivery of your belongings.
              With years of experience in the industry, we have built a
              reputation for trustworthiness and reliability that you can count
              on.
            </p>
            <h2 className="htag mt-5">Our Range of Services</h2>
            <p className="ptag">
              Eazymov offers a comprehensive suite of services tailored to meet
              your specific moving needs. Whether you are relocating across town
              or across the globe, our expert team is equipped to handle all
              aspects of your move. From packing and loading to transportation
              and unloading, we provide end-to-end solutions that ensure your
              belongings arrive safely and on time. Our services also extend to
              specialized moves, including office relocations, industrial moves,
              and international shipments, ensuring that no matter the
              challenge, Eazymov has you covered.
            </p>
            <h2 className="htag mt-5">Our Guarantee of Satisfaction</h2>
            <p className="ptag">
              At Eazymov, your satisfaction is our top priority. We guarantee a
              seamless moving experience backed by our commitment to quality and
              customer care. Our team is trained to handle your possessions with
              the utmost care, and we use the latest technology and equipment to
              ensure their safe transport. If for any reason you are not
              satisfied with our services, we are committed to making it right.
              We believe in building lasting relationships with our customers,
              which is why we offer a 100% satisfaction guarantee on all our
              services.
            </p>
            <h2 className="htag mt-5">The Eazymov Difference</h2>
            <p className="ptag">
              What sets Eazymov apart from other moving companies is our
              unwavering dedication to excellence. We are not just a service
              provider; we are your partners in a successful move. Our attention
              to detail, commitment to customer satisfaction, and innovative
              solutions make us the preferred choice for thousands of customers
              worldwide. Whether you are moving your home or your business,
              Eazymov ensures a smooth transition, allowing you to focus on what
              matters most.
            </p>
            <h2 className="htag mt-5">Why Choose Eazymov?</h2>
            <p className="ptag">
              Choosing Eazymov means choosing peace of mind. Our team of
              professionals is dedicated to making your move as stress-free as
              possible. With our extensive network, state-of-the-art technology,
              and personalized service, we provide a moving experience that is
              unmatched in the industry. We handle every aspect of your move
              with precision and care, ensuring that your belongings are in the
              safest hands. When you choose Eazymov, you are choosing a company
              that values your trust and works tirelessly to exceed your
              expectations.
            </p>
            <h2 className="htag mt-5">Our Values and Ethics</h2>
            <p className="ptag">
              Integrity, reliability, and customer focus are at the heart of
              everything we do at Eazymov. We believe in conducting our business
              with the highest ethical standards, ensuring that every move we
              undertake is carried out with honesty and fairness. Our commitment
              to these values has earned us the trust of countless customers,
              and we continue to uphold these principles in all our operations.
              At Eazymov, we don’t just move belongings; we move lives, and we
              do so with the respect and care that our customers deserve.
            </p>
          </div>
        </div>
      </div>
      {/* <ServiceCal /> */}

      <LinaerStepper />
      <Customer />
      <AccordionExpandIcon />
      <RequestaQuote />
      <Servicepro />
      <Foter2comp />
    </Layout>
  );
};

export default Homepage;
