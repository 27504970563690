import React from "react";
import Layout from "../comp/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import bg from "../img/about-page-bg.jpg";
import { Link, useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/cartrans.webp",
      head: "Car Transportation",
      text: "",
      to: "car-transportation",
    },
    {
      img: "/img/biketransport.jpg",
      head: "Bike Transportation",
      text: "",
      to: "bike-transportation",
    },
    {
      img: "/img/transportation.jpg",
      head: "Transport",
      text: "",
      to: "transport",
    },
    {
      img: "/img/packer.jpg",
      head: "Packers & Movers",
      text: "",
      to: "packers-movers",
    },
    {
      img: "/img/intmovers.jpg",
      head: "International Movers",
      text: "",
      to: "international-movers",
    },
    {
      img: "/img/hiretruck.png",
      head: "Hire Truck Tempo",
      text: "",
      to: "hire-truck-tempo",
    },
    {
      img: "/img/petre.jpg",
      head: "Pet Relocation",
      text: "",
      to: "pet-relocation",
    },
    {
      img: "/img/cargo.webp",
      head: "Cargo",
      text: "",
      to: "cargo",
    },
    {
      img: "/img/warehousing.jpg",
      head: "Warehousing",
      text: "",
      to: "warehouse",
    },
  ];
  return (
    <Layout>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>SERVICES </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"} Services
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid p-5">
        <div className="row">
          {card.map((e) => (
            <>
              <div className="col-lg-4 col-md-6 col-sm-12 my-4">
                <img
                  src={e.img}
                  alt="img"
                  style={{ height: "60%", width: "100%" }}
                />
                <h5
                  style={{
                    fontSize: "19px",
                    fontWeight: "700",
                    letterSpacing: "1px",
                  }}
                  className="my-3"
                >
                  {e.head}
                </h5>
                <p style={{ textAlign: "justify", fontSize: "14px" }}>
                  {e.text}
                </p>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button
                    onClick={() => {
                      navigate(`/${e.to}`);
                    }}
                    className="reqbtn"
                  >
                    READ MORE
                  </button>
                  <button
                    style={{
                      width: "50px",
                      border: "none",
                      backgroundColor: "#08487E",
                      color: "white",
                    }}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Services;
