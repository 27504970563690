import React, { useState } from "react";
import {
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Basic Information", "Transport Details"];
}

const initialServiceOptions = {
  WAREHOUSES: [
    "Househole Storage",
    "Business Storage",
    "commercial Warehouse for Rent",
    "Household Warehouse for Rent",
  ],
  CARGO: [
    "Air Cargo",
    "Rail Cargo",
    "Road Cargo",
    "Sea International Cargo",
    "Air International Cargo",
  ],
  "HIRE-TRUCK-TEMPO": [
    "Chota Hathi",
    "Tata Ace And Chota Hathi",
    "Ape Xtra (Mahindra)",
    "Mahindra Porter 600",
    "Mahindra Porter 100",
    "Tata 407",
    "Tata Ace Open",
    "Mahindra PickUp",
    "Eicher Truck",
    "Mini Truck",
  ],
  "PET-RELOCATION": ["No Sub-Services"],
  "CAR-TRANSPORTATION": [
    "Luxury Car & Bike Shifting Services",
    "Car Packers & Movers Services",
    "Bike Transportation Services",
    "SUV Car Service",
  ],
  "BIKE-TRANSPORTATION": ["No Sub-Services"],
  "INTERNATIONAL-MOVERS": [
    "Packers & Movers",
    "Househole+Car",
    "Household+Bike",
    "Car-Transportation",
    "Bike-Transportation",
    "Furniture Shifting",
    "Pet-Relocation",
  ],
  "PACKERS & MOVERS": [
    "Home Shifting Services",
    "Office Shifting Services",
    "Local Shifting Services",
    "Furniture Shifting Services",
    "Bed Shifting Services",
    "TV Shifting Services",
    "Sofa Shifting Services",
    "Fridge Shifting Services",
  ],
  TRANSPORT: ["Open Truck", "Small Truck", "Container"],
};

// Mapping of sub-services to their corresponding capacities
const capacityOptionsMapping = {
  "Open Truck": [
    "20 Feet Open All Side ODC (7 Ton)",
    "28-32 Feet Open Trailor JCB ODC (8 Ton)",
    "32 Feet Open Trailor JCB ODC (8 Ton)",
    "32 Feet Open Trailor JCB ODC (25 Ton)",
    "40 Feet Open Trailor JCB ODC (32 Ton)",
  ],
  Container: [
    "Container 32 FT SXL (7 Ton)",
    "Container 32 FT SXL HQ(7 Ton)",
    "Container 32 FT MXL HQ (14 Ton)",
    "Container 20 FT (6.5 Ton)",
  ],
  "Small Truck": [
    "Tata Ace (850 Kgs)",
    "Ashok Layland Dost (1 Ton)",
    "Mahindra Bolero Pickup (1.5 Ton)",
    "Tata 407 (2.5 Ton)",
    "Eicher 14FT (4 Ton)",
    "Eicher 17FT (5 Ton)",
    "Eicher 19FT (7 Ton)",
    "Eicher 19FT (8 Ton)",
    "Eicher 19FT (9 Ton)",
    "Tata 22FT (10 Ton)",
    "Tata Truck 6Tyre (9 Ton)",
    "Tauras 16 T 10 Tyre (16 Ton)",
    "Tauras 21 T 12 Tyre (21 Ton)",
    "Tauras 25 T 14 Tyre (25 Ton)",
  ],
  // Add more mappings as needed
};

function getStepContent(
  step,
  name,
  setName,
  phone,
  setPhone,
  email,
  setEmail,
  from,
  setFrom,
  to,
  setTo,
  service,
  setService,
  date,
  setDate,
  subService,
  setSubService,
  serviceOptions,
  subSubService1,
  setSubSubService1,
  subSubService2,
  setSubSubService2,
  showSubSubServices
) {
  const handleServiceChange = (e) => {
    const selectedService = e.target.value;
    setService(selectedService);
    setSubService(""); // Reset sub-service when service changes
    setSubSubService1(""); // Reset sub-sub-service 1
    setSubSubService2(""); // Reset sub-sub-service 2
  };

  const handleSubServiceChange = (e) => {
    const selectedSubService = e.target.value;
    setSubService(selectedSubService);

    // Update capacity options based on selected sub-service
    const newCapacityOptions = capacityOptionsMapping[selectedSubService] || [];
    setSubSubService1(
      newCapacityOptions.length > 0 ? newCapacityOptions[0] : ""
    );
  };

  const subServiceOptions = serviceOptions[service] || [];

  const indiaStatesAndDistricts = [
    { city: "AndraPradesh Anantapur" },
    { city: "AndraPradesh Chittoor" },
    { city: "AndraPradesh East Godavari" },
    { city: "AndraPradesh Guntur" },
    { city: "AndraPradesh Kadapa" },
    { city: "AndraPradesh Krishna" },
    { city: "AndraPradesh Kurnool" },
    { city: "AndraPradesh Prakasam" },
    { city: "AndraPradesh Nellore" },
    { city: "AndraPradesh Srikakulam" },
    { city: "AndraPradesh Visakhapatnam" },
    { city: "AndraPradesh Vizianagaram" },
    { city: "AndraPradesh West Godavari" },
    { city: "ArunachalPradesh Anjaw" },
    { city: "ArunachalPradesh Changlang" },
    { city: "ArunachalPradesh Dibang Valley" },
    { city: "ArunachalPradesh East Kameng" },
    { city: "ArunachalPradesh East Siang" },
    { city: "ArunachalPradesh Kra Daadi" },
    { city: "ArunachalPradesh Kurung Kumey" },
    { city: "ArunachalPradesh Lohit" },
    { city: "ArunachalPradesh Longding" },
    { city: "ArunachalPradesh Lower Dibang Valley" },
    { city: "ArunachalPradesh Lower Subansiri" },
    { city: "ArunachalPradesh Namsai" },
    { city: "ArunachalPradesh Papum Pare" },
    { city: "ArunachalPradesh Siang" },
    { city: "ArunachalPradesh Tawang" },
    { city: "ArunachalPradesh Tirap" },
    { city: "ArunachalPradesh Upper Siang" },
    { city: "ArunachalPradesh Upper Subansiri" },
    { city: "ArunachalPradesh West Kameng" },
    { city: "ArunachalPradesh West Siang" },
    { city: "ArunachalPradesh Itanagar" },
    { city: "Assam Baksa" },
    { city: "Assam Barpeta" },
    { city: "Assam Biswanath" },
    { city: "Assam Bongaigaon" },
    { city: "Assam Cachar" },
    { city: "Assam Charaideo" },
    { city: "Assam Chirang" },
    { city: "Assam Darrang" },
    { city: "Assam Dhemaji" },
    { city: "Assam Dhubri" },
    { city: "Assam Dibrugarh" },
    { city: "Assam Goalpara" },
    { city: "Assam Golaghat" },
    { city: "Assam Hailakandi" },
    { city: "Assam Hojai" },
    { city: "Assam Jorhat" },
    { city: "Assam Kamrup Metropolitan" },
    { city: "Assam Kamrup (Rural)" },
    { city: "Assam Karbi Anglong" },
    { city: "Assam Karimganj" },
    { city: "Assam Kokrajhar" },
    { city: "Assam Lakhimpur" },
    { city: "Assam Majuli" },
    { city: "Assam Morigaon" },
    { city: "Assam Nagaon" },
    { city: "Assam Nalbari" },
    { city: "Assam Dima Hasao" },
    { city: "Assam Sivasagar" },
    { city: "Assam Sonitpur" },
    { city: "Assam South Salmara Mankachar" },
    { city: "Assam Tinsukia" },
    { city: "Assam Udalguri" },
    { city: "Assam West Karbi Anglong" },
    { city: "Bihar Araria" },
    { city: "Bihar Arwal" },
    { city: "Bihar Aurangabad" },
    { city: "Bihar Banka" },
    { city: "Bihar Begusarai" },
    { city: "Bihar Bhagalpur" },
    { city: "Bihar Bhojpur" },
    { city: "Bihar Buxar" },
    { city: "Bihar Darbhanga" },
    { city: "Bihar East Champaran" },
    { city: "Bihar Gaya" },
    { city: "Bihar Gopalganj" },
    { city: "Bihar Jamui" },
    { city: "Bihar Jehanabad" },
    { city: "Bihar Kaimur" },
    { city: "Bihar Katihar" },
    { city: "Bihar Khagaria" },
    { city: "Bihar Kishanganj" },
    { city: "Bihar Lakhisarai" },
    { city: "Bihar Madhepura" },
    { city: "Bihar Madhubani" },
    { city: "Bihar Munger" },
    { city: "Bihar Muzaffarpur" },
    { city: "Bihar Nalanda" },
    { city: "Bihar Nawada" },
    { city: "Bihar Patna" },
    { city: "Bihar Purnia" },
    { city: "Bihar Rohtas" },
    { city: "Bihar Saharsa" },
    { city: "Bihar Samastipur" },
    { city: "Bihar Saran" },
    { city: "Bihar Sheikhpura" },
    { city: "Bihar Sheohar" },
    { city: "Bihar Sitamarhi" },
    { city: "Bihar Siwan" },
    { city: "Bihar Supaul" },
    { city: "Bihar Vaishali" },
    { city: "Bihar West Champaran" },
    { city: "Chhattisgarh Balod" },
    { city: "Chhattisgarh Baloda Bazar" },
    { city: "Chhattisgarh Balrampur" },
    { city: "Chhattisgarh Bastar" },
    { city: "Chhattisgarh Bemetara" },
    { city: "Chhattisgarh Bijapur" },
    { city: "Chhattisgarh Bilaspur" },
    { city: "Chhattisgarh Dantewada" },
    { city: "Chhattisgarh Dhamtari" },
    { city: "Chhattisgarh Durg" },
    { city: "Chhattisgarh Gariaband" },
    { city: "Chhattisgarh Janjgir Champa" },
    { city: "Chhattisgarh Jashpur" },
    { city: "Chhattisgarh Kabirdham" },
    { city: "Chhattisgarh Kanker" },
    { city: "Chhattisgarh Kondagaon" },
    { city: "Chhattisgarh Korba" },
    { city: "Chhattisgarh Koriya" },
    { city: "Chhattisgarh Mahasamund" },
    { city: "Chhattisgarh Mungeli" },
    { city: "Chhattisgarh Narayanpur" },
    { city: "Chhattisgarh Raigarh" },
    { city: "Chhattisgarh Raipur" },
    { city: "Chhattisgarh Rajnandgaon" },
    { city: "Chhattisgarh Sukma" },
    { city: "Chhattisgarh Surajpur" },
    { city: "Chhattisgarh Surguja" },
    { city: "Goa North Goa" },
    { city: "Goa South Goa" },
    { city: "Gujarat Ahmedabad" },
    { city: "Gujarat Amreli" },
    { city: "Gujarat Anand" },
    { city: "Gujarat Aravalli" },
    { city: "Gujarat Banaskantha" },
    { city: "Gujarat Bharuch" },
    { city: "Gujarat Bhavnagar" },
    { city: "Gujarat Botad" },
    { city: "Gujarat Chhota Udaipur" },
    { city: "Gujarat Dahod" },
    { city: "Gujarat Dang" },
    { city: "Gujarat Devbhoomi Dwarka" },
    { city: "Gujarat Gandhinagar" },
    { city: "Gujarat Gir Somnath" },
    { city: "Gujarat Jamnagar" },
    { city: "Gujarat Junagadh" },
    { city: "Gujarat Kheda" },
    { city: "Gujarat Kutch" },
    { city: "Gujarat Mahisagar" },
    { city: "Gujarat Mehsana" },
    { city: "Gujarat Morbi" },
    { city: "Gujarat Narmada" },
    { city: "Gujarat Navsari" },
    { city: "Gujarat Panchmahal" },
    { city: "Gujarat Patan" },
    { city: "Gujarat Porbandar" },
    { city: "Gujarat Rajkot" },
    { city: "Gujarat Sabarkantha" },
    { city: "Gujarat Surat" },
    { city: "Gujarat Surendranagar" },
    { city: "Gujarat Tapi" },
    { city: "Gujarat Vadodara" },
    { city: "Gujarat Valsad" },
    { city: "Haryana Ambala" },
    { city: "Haryana Bhiwani" },
    { city: "Haryana Charkhi Dadri" },
    { city: "Haryana Faridabad" },
    { city: "Haryana Fatehabad" },
    { city: "Haryana Gurugram" },
    { city: "Haryana Hisar" },
    { city: "Haryana Jhajjar" },
    { city: "Haryana Jind" },
    { city: "Haryana Kaithal" },
    { city: "Haryana Karnal" },
    { city: "Haryana Kurukshetra" },
    { city: "Haryana Mahendragarh" },
    { city: "Haryana Mewat" },
    { city: "Haryana Palwal" },
    { city: "Haryana Panchkula" },
    { city: "Haryana Panipat" },
    { city: "Haryana Rewari" },
    { city: "Haryana Rohtak" },
    { city: "Haryana Sirsa" },
    { city: "Haryana Sonipat" },
    { city: "Haryana Yamunanagar" },
    { city: "HimachalPradesh Bilaspur" },
    { city: "HimachalPradesh Chamba" },
    { city: "HimachalPradesh Hamirpur" },
    { city: "HimachalPradesh Kangra" },
    { city: "HimachalPradesh Kinnaur" },
    { city: "HimachalPradesh Kullu" },
    { city: "HimachalPradesh Lahaul Spiti" },
    { city: "HimachalPradesh Mandi" },
    { city: "HimachalPradesh Shimla" },
    { city: "HimachalPradesh Sirmaur" },
    { city: "HimachalPradesh Solan" },
    { city: "HimachalPradesh Una" },
    { city: "JammuKashmir Anantnag" },
    { city: "JammuKashmir Bandipora" },
    { city: "JammuKashmir Baramulla" },
    { city: "JammuKashmir Budgam" },
    { city: "JammuKashmir Doda" },
    { city: "JammuKashmir Ganderbal" },
    { city: "JammuKashmir Jammu" },
    { city: "JammuKashmir Kargil" },
    { city: "JammuKashmir Kathua" },
    { city: "JammuKashmir Kishtwar" },
    { city: "JammuKashmir Kulgam" },
    { city: "JammuKashmir Kupwara" },
    { city: "JammuKashmir Leh" },
    { city: "JammuKashmir Poonch" },
    { city: "JammuKashmir Pulwama" },
    { city: "JammuKashmir Rajouri" },
    { city: "JammuKashmir Ramban" },
    { city: "JammuKashmir Reasi" },
    { city: "JammuKashmir Samba" },
    { city: "JammuKashmir Shopian" },
    { city: "JammuKashmir Srinagar" },
    { city: "JammuKashmir Udhampur" },
    { city: "Jharkhand Bokaro" },
    { city: "Jharkhand Chatra" },
    { city: "Jharkhand Deoghar" },
    { city: "Jharkhand Dhanbad" },
    { city: "Jharkhand Dumka" },
    { city: "Jharkhand East Singhbhum" },
    { city: "Jharkhand Garhwa" },
    { city: "Jharkhand Giridih" },
    { city: "Jharkhand Godda" },
    { city: "Jharkhand Gumla" },
    { city: "Jharkhand Hazaribagh" },
    { city: "Jharkhand Jamtara" },
    { city: "Jharkhand Khunti" },
    { city: "Jharkhand Koderma" },
    { city: "Jharkhand Latehar" },
    { city: "Jharkhand Lohardaga" },
    { city: "Jharkhand Pakur" },
    { city: "Jharkhand Palamu" },
    { city: "Jharkhand Ramgarh" },
    { city: "Jharkhand Ranchi" },
    { city: "Jharkhand Sahebganj" },
    { city: "Jharkhand Seraikela Kharsawan" },
    { city: "Jharkhand Simdega" },
    { city: "Jharkhand West Singhbhum" },
    { city: "Karnataka Bagalkot" },
    { city: "Karnataka Bangalore Rural" },
    { city: "Karnataka Bangalore Urban" },
    { city: "Karnataka Belgaum" },
    { city: "Karnataka Bellary" },
    { city: "Karnataka Bidar" },
    { city: "Karnataka Vijayapura" },
    { city: "Karnataka Chamarajanagar" },
    { city: "Karnataka Chikkaballapur" },
    { city: "Karnataka Chikkamagaluru" },
    { city: "Karnataka Chitradurga" },
    { city: "Karnataka Dakshina Kannada" },
    { city: "Karnataka Davanagere" },
    { city: "Karnataka Dharwad" },
    { city: "Karnataka Gadag" },
    { city: "Karnataka Gulbarga" },
    { city: "Karnataka Hassan" },
    { city: "Karnataka Haveri" },
    { city: "Karnataka Kodagu" },
    { city: "Karnataka Kolar" },
    { city: "Karnataka Koppal" },
    { city: "Karnataka Mandya" },
    { city: "Karnataka Mysore" },
    { city: "Karnataka Raichur" },
    { city: "Karnataka Ramanagara" },
    { city: "Karnataka Shimoga" },
    { city: "Karnataka Tumkur" },
    { city: "Karnataka Udupi" },
    { city: "Karnataka Uttara Kannada" },
    { city: "Karnataka Yadgir" },
    { city: "Kerala Alappuzha" },
    { city: "Kerala Ernakulam" },
    { city: "Kerala Idukki" },
    { city: "Kerala Kannur" },
    { city: "Kerala Kasaragod" },
    { city: "Kerala Kollam" },
    { city: "Kerala Kottayam" },
    { city: "Kerala Kozhikode" },
    { city: "Kerala Malappuram" },
    { city: "Kerala Palakkad" },
    { city: "Kerala Pathanamthitta" },
    { city: "Kerala Thiruvananthapuram" },
    { city: "Kerala Thrissur" },
    { city: "Kerala Wayanad" },
    { city: "MadhyaPradesh Agar Malwa" },
    { city: "MadhyaPradesh Alirajpur" },
    { city: "MadhyaPradesh Anuppur" },
    { city: "MadhyaPradesh Ashoknagar" },
    { city: "MadhyaPradesh Balaghat" },
    { city: "MadhyaPradesh Barwani" },
    { city: "MadhyaPradesh Betul" },
    { city: "MadhyaPradesh Bhind" },
    { city: "MadhyaPradesh Bhopal" },
    { city: "MadhyaPradesh Burhanpur" },
    { city: "MadhyaPradesh Chhatarpur" },
    { city: "MadhyaPradesh Chhindwara" },
    { city: "MadhyaPradesh Damoh" },
    { city: "MadhyaPradesh Datia" },
    { city: "MadhyaPradesh Dewas" },
    { city: "MadhyaPradesh Dhar" },
    { city: "MadhyaPradesh Dindori" },
    { city: "MadhyaPradesh Guna" },
    { city: "MadhyaPradesh Gwalior" },
    { city: "MadhyaPradesh Harda" },
    { city: "MadhyaPradesh Hoshangabad" },
    { city: "MadhyaPradesh Indore" },
    { city: "MadhyaPradesh Jabalpur" },
    { city: "MadhyaPradesh Jhabua" },
    { city: "MadhyaPradesh Katni" },
    { city: "MadhyaPradesh Khandwa" },
    { city: "MadhyaPradesh Khargone" },
    { city: "MadhyaPradesh Mandla" },
    { city: "MadhyaPradesh Mandsaur" },
    { city: "MadhyaPradesh Morena" },
    { city: "MadhyaPradesh Narsinghpur" },
    { city: "MadhyaPradesh Neemuch" },
    { city: "MadhyaPradesh Panna" },
    { city: "MadhyaPradesh Raisen" },
    { city: "MadhyaPradesh Rajgarh" },
    { city: "MadhyaPradesh Ratlam" },
    { city: "MadhyaPradesh Rewa" },
    { city: "MadhyaPradesh Sagar" },
    { city: "MadhyaPradesh Satna" },
    { city: "MadhyaPradesh Sehore" },
    { city: "MadhyaPradesh Seoni" },
    { city: "MadhyaPradesh Shahdol" },
    { city: "MadhyaPradesh Shajapur" },
    { city: "MadhyaPradesh Sheopur" },
    { city: "MadhyaPradesh Shivpuri" },
    { city: "MadhyaPradesh Sidhi" },
    { city: "MadhyaPradesh Singrauli" },
    { city: "MadhyaPradesh Tikamgarh" },
    { city: "MadhyaPradesh Ujjain" },
    { city: "MadhyaPradesh Umaria" },
    { city: "MadhyaPradesh Vidisha" },
    { city: "Maharashtra Ahmednagar" },
    { city: "Maharashtra Akola" },
    { city: "Maharashtra Amravati" },
    { city: "Maharashtra Aurangabad" },
    { city: "Maharashtra Beed" },
    { city: "Maharashtra Bhandara" },
    { city: "Maharashtra Buldhana" },
    { city: "Maharashtra Chandrapur" },
    { city: "Maharashtra Dhule" },
    { city: "Maharashtra Gadchiroli" },
    { city: "Maharashtra Gondia" },
    { city: "Maharashtra Hingoli" },
    { city: "Maharashtra Jalgaon" },
    { city: "Maharashtra Jalna" },
    { city: "Maharashtra Kolhapur" },
    { city: "Maharashtra Latur" },
    { city: "Maharashtra Mumbai City" },
    { city: "Maharashtra Mumbai Suburban" },
    { city: "Maharashtra Nagpur" },
    { city: "Maharashtra Nanded" },
    { city: "Maharashtra Nandurbar" },
    { city: "Maharashtra Nashik" },
    { city: "Maharashtra Osmanabad" },
    { city: "Maharashtra Palghar" },
    { city: "Maharashtra Parbhani" },
    { city: "Maharashtra Pune" },
    { city: "Maharashtra Raigad" },
    { city: "Maharashtra Ratnagiri" },
    { city: "Maharashtra Sangli" },
    { city: "Maharashtra Satara" },
    { city: "Maharashtra Sindhudurg" },
    { city: "Maharashtra Solapur" },
    { city: "Maharashtra Thane" },
    { city: "Maharashtra Wardha" },
    { city: "Maharashtra Washim" },
    { city: "Maharashtra Yavatmal" },
    { city: "Manipur Bishnupur" },
    { city: "Manipur Chandel" },
    { city: "Manipur Churachandpur" },
    { city: "Manipur Imphal East" },
    { city: "Manipur Imphal West" },
    { city: "Manipur Jiribam" },
    { city: "Manipur Kakching" },
    { city: "Manipur Kamjong" },
    { city: "Manipur Kangpokpi" },
    { city: "Manipur Noney" },
    { city: "Manipur Pherzawl" },
    { city: "Manipur Senapati" },
    { city: "Manipur Tamenglong" },
    { city: "Manipur Tengnoupal" },
    { city: "Manipur Thoubal" },
    { city: "Manipur Ukhrul" },
    { city: "Meghalaya East Garo Hills" },
    { city: "Meghalaya East Jaintia Hills" },
    { city: "Meghalaya East Khasi Hills" },
    { city: "Meghalaya North Garo Hills" },
    { city: "Meghalaya Ri Bhoi" },
    { city: "Meghalaya South Garo Hills" },
    { city: "Meghalaya South West Garo Hills" },
    { city: "Meghalaya South West Khasi Hills" },
    { city: "Meghalaya West Garo Hills" },
    { city: "Meghalaya West Jaintia Hills" },
    { city: "Meghalaya West Khasi Hills" },
    { city: "Mizoram Aizawl" },
    { city: "Mizoram Champhai" },
    { city: "Mizoram Kolasib" },
    { city: "Mizoram Lawngtlai" },
    { city: "Mizoram Lunglei" },
    { city: "Mizoram Mamit" },
    { city: "Mizoram Saiha" },
    { city: "Mizoram Serchhip" },
    { city: "Nagaland Dimapur" },
    { city: "Nagaland Kiphire" },
    { city: "Nagaland Kohima" },
    { city: "Nagaland Longleng" },
    { city: "Nagaland Mokokchung" },
    { city: "Nagaland Mon" },
    { city: "Nagaland Peren" },
    { city: "Nagaland Phek" },
    { city: "Nagaland Tuensang" },
    { city: "Nagaland Wokha" },
    { city: "Nagaland Zunheboto" },
    { city: "Odisha Angul" },
    { city: "Odisha Balangir" },
    { city: "Odisha Balasore" },
    { city: "Odisha Bargarh" },
    { city: "Odisha Bhadrak" },
    { city: "Odisha Boudh" },
    { city: "Odisha Cuttack" },
    { city: "Odisha Debagarh" },
    { city: "Odisha Dhenkanal" },
    { city: "Odisha Gajapati" },
    { city: "Odisha Ganjam" },
    { city: "Odisha Jagatsinghpur" },
    { city: "Odisha Jajpur" },
    { city: "Odisha Jharsuguda" },
    { city: "Odisha Kalahandi" },
    { city: "Odisha Kandhamal" },
    { city: "Odisha Kendrapara" },
    { city: "Odisha Kendujhar" },
    { city: "Odisha Khordha" },
    { city: "Odisha Koraput" },
    { city: "Odisha Malkangiri" },
    { city: "Odisha Mayurbhanj" },
    { city: "Odisha Nabarangpur" },
    { city: "Odisha Nayagarh" },
    { city: "Odisha Nuapada" },
    { city: "Odisha Puri" },
    { city: "Odisha Rayagada" },
    { city: "Odisha Sambalpur" },
    { city: "Odisha Subarnapur" },
    { city: "Odisha Sundergarh" },
    { city: "Punjab Amritsar" },
    { city: "Punjab Barnala" },
    { city: "Punjab Bathinda" },
    { city: "Punjab Faridkot" },
    { city: "Punjab Fatehgarh Sahib" },
    { city: "Punjab Fazilka" },
    { city: "Punjab Firozpur" },
    { city: "Punjab Gurdaspur" },
    { city: "Punjab Hoshiarpur" },
    { city: "Punjab Jalandhar" },
    { city: "Punjab Kapurthala" },
    { city: "Punjab Ludhiana" },
    { city: "Punjab Mansa" },
    { city: "Punjab Moga" },
    { city: "Punjab Mohali" },
    { city: "Punjab Muktsar" },
    { city: "Punjab Pathankot" },
    { city: "Punjab Patiala" },
    { city: "Punjab Rupnagar" },
    { city: "Punjab Sangrur" },
    { city: "Punjab Shaheed Bhagat Singh Nagar" },
    { city: "Punjab Tarn Taran" },
    { city: "Rajasthan Ajmer" },
    { city: "Rajasthan Alwar" },
    { city: "Rajasthan Banswara" },
    { city: "Rajasthan Baran" },
    { city: "Rajasthan Barmer" },
    { city: "Rajasthan Bharatpur" },
    { city: "Rajasthan Bhilwara" },
    { city: "Rajasthan Bikaner" },
    { city: "Rajasthan Bundi" },
    { city: "Rajasthan Chittorgarh" },
    { city: "Rajasthan Churu" },
    { city: "Rajasthan Dausa" },
    { city: "Rajasthan Dholpur" },
    { city: "Rajasthan Dungarpur" },
    { city: "Rajasthan Hanumangarh" },
    { city: "Rajasthan Jaipur" },
    { city: "Rajasthan Jaisalmer" },
    { city: "Rajasthan Jalore" },
    { city: "Rajasthan Jhalawar" },
    { city: "Rajasthan Jhunjhunu" },
    { city: "Rajasthan Jodhpur" },
    { city: "Rajasthan Karauli" },
    { city: "Rajasthan Kota" },
    { city: "Rajasthan Nagaur" },
    { city: "Rajasthan Pali" },
    { city: "Rajasthan Pratapgarh" },
    { city: "Rajasthan Rajsamand" },
    { city: "Rajasthan Sawai Madhopur" },
    { city: "Rajasthan Sikar" },
    { city: "Rajasthan Sirohi" },
    { city: "Rajasthan Sri Ganganagar" },
    { city: "Rajasthan Tonk" },
    { city: "Rajasthan Udaipur" },
    { city: "Sikkim East Sikkim" },
    { city: "Sikkim North Sikkim" },
    { city: "Sikkim South Sikkim" },
    { city: "Sikkim West Sikkim" },
    { city: "TamilNadu Ariyalur" },
    { city: "TamilNadu Chengalpet" },
    { city: "TamilNadu Chennai" },
    { city: "TamilNadu Coimbatore" },
    { city: "TamilNadu Cuddalore" },
    { city: "TamilNadu Dharmapuri" },
    { city: "TamilNadu Dindigul" },
    { city: "TamilNadu Erode" },
    { city: "TamilNadu Kallakurichi" },
    { city: "TamilNadu Kanchipuram" },
    { city: "TamilNadu Kanyakumari" },
    { city: "TamilNadu Karur" },
    { city: "TamilNadu Krishnagiri" },
    { city: "TamilNadu Madurai" },
    { city: "TamilNadu Nagapattinam" },
    { city: "TamilNadu Namakkal" },
    { city: "TamilNadu Nilgiris" },
    { city: "TamilNadu Perambalur" },
    { city: "TamilNadu Pudukkottai" },
    { city: "TamilNadu Ramanathapuram" },
    { city: "TamilNadu Ranipet" },
    { city: "TamilNadu Salem" },
    { city: "TamilNadu Sivaganga" },
    { city: "TamilNadu Tenkasi" },
    { city: "TamilNadu Thanjavur" },
    { city: "TamilNadu Theni" },
    { city: "TamilNadu Thoothukudi" },
    { city: "TamilNadu Tiruchirappalli" },
    { city: "TamilNadu Tirunelveli" },
    { city: "TamilNadu Tirupathur" },
    { city: "TamilNadu Tiruppur" },
    { city: "TamilNadu Tiruvallur" },
    { city: "TamilNadu Tiruvannamalai" },
    { city: "TamilNadu Tiruvarur" },
    { city: "TamilNadu Vellore" },
    { city: "TamilNadu Viluppuram" },
    { city: "TamilNadu Virudhunagar" },
    { city: "Telangana Adilabad" },
    { city: "Telangana Bhadradri Kothagudem" },
    { city: "Telangana Hyderabad" },
    { city: "Telangana Jagtial" },
    { city: "Telangana Jangaon" },
    { city: "Telangana Jayashankar Bhupalapally" },
    { city: "Telangana Jogulamba Gadwal" },
    { city: "Telangana Kamareddy" },
    { city: "Telangana Karimnagar" },
    { city: "Telangana Khammam" },
    { city: "Telangana Kumuram Bheem Asifabad" },
    { city: "Telangana Mahabubabad" },
    { city: "Telangana Mahabubnagar" },
    { city: "Telangana Mancherial" },
    { city: "Telangana Medak" },
    { city: "Telangana Medchal" },
    { city: "Telangana Mulugu" },
    { city: "Telangana Nagarkurnool" },
    { city: "Telangana Nalgonda" },
    { city: "Telangana Narayanpet" },
    { city: "Telangana Nirmal" },
    { city: "Telangana Nizamabad" },
    { city: "Telangana Peddapalli" },
    { city: "Telangana Rajanna Sircilla" },
    { city: "Telangana Rangareddy" },
    { city: "Telangana Sangareddy" },
    { city: "Telangana Siddipet" },
    { city: "Telangana Suryapet" },
    { city: "Telangana Vikarabad" },
    { city: "Telangana Wanaparthy" },
    { city: "Telangana Warangal (Rural)" },
    { city: "Telangana Warangal (Urban)" },
    { city: "Telangana Yadadri Bhuvanagiri" },
    { city: "Tripura Dhalai" },
    { city: "Tripura Gomati" },
    { city: "Tripura Khowai" },
    { city: "Tripura North Tripura" },
    { city: "Tripura Sepahijala" },
    { city: "Tripura South Tripura" },
    { city: "Tripura Unakoti" },
    { city: "Tripura West Tripura" },
    { city: "UttarPradesh Agra" },
    { city: "UttarPradesh Aligarh" },
    { city: "UttarPradesh Allahabad" },
    { city: "UttarPradesh Ambedkar Nagar" },
    { city: "UttarPradesh Amethi" },
    { city: "UttarPradesh Amroha" },
    { city: "UttarPradesh Auraiya" },
    { city: "UttarPradesh Azamgarh" },
    { city: "UttarPradesh Baghpat" },
    { city: "UttarPradesh Bahraich" },
    { city: "UttarPradesh Ballia" },
    { city: "UttarPradesh Balrampur" },
    { city: "UttarPradesh Banda" },
    { city: "UttarPradesh Barabanki" },
    { city: "UttarPradesh Bareilly" },
    { city: "UttarPradesh Basti" },
    { city: "UttarPradesh Bhadohi" },
    { city: "UttarPradesh Bijnor" },
    { city: "UttarPradesh Budaun" },
    { city: "UttarPradesh Bulandshahr" },
    { city: "UttarPradesh Chandauli" },
    { city: "UttarPradesh Chitrakoot" },
    { city: "UttarPradesh Deoria" },
    { city: "UttarPradesh Etah" },
    { city: "UttarPradesh Etawah" },
    { city: "UttarPradesh Faizabad" },
    { city: "UttarPradesh Farrukhabad" },
    { city: "UttarPradesh Fatehpur" },
    { city: "UttarPradesh Firozabad" },
    { city: "UttarPradesh Gautam Buddha Nagar" },
    { city: "UttarPradesh Ghaziabad" },
    { city: "UttarPradesh Ghazipur" },
    { city: "UttarPradesh Gonda" },
    { city: "UttarPradesh Gorakhpur" },
    { city: "UttarPradesh Hamirpur" },
    { city: "UttarPradesh Hapur" },
    { city: "UttarPradesh Hardoi" },
    { city: "UttarPradesh Hathras" },
    { city: "UttarPradesh Jalaun" },
    { city: "UttarPradesh Jaunpur" },
    { city: "UttarPradesh Jhansi" },
    { city: "UttarPradesh Kannauj" },
    { city: "UttarPradesh Kanpur Dehat" },
    { city: "UttarPradesh Kanpur Nagar" },
    { city: "UttarPradesh Kasganj" },
    { city: "UttarPradesh Kaushambi" },
    { city: "UttarPradesh Kushinagar" },
    { city: "UttarPradesh Lakhimpur Kheri" },
    { city: "UttarPradesh Lalitpur" },
    { city: "UttarPradesh Lucknow" },
    { city: "UttarPradesh Maharajganj" },
    { city: "UttarPradesh Mahoba" },
    { city: "UttarPradesh Mainpuri" },
    { city: "UttarPradesh Mathura" },
    { city: "UttarPradesh Mau" },
    { city: "UttarPradesh Meerut" },
    { city: "UttarPradesh Mirzapur" },
    { city: "UttarPradesh Moradabad" },
    { city: "UttarPradesh Muzaffarnagar" },
    { city: "UttarPradesh Pilibhit" },
    { city: "UttarPradesh Pratapgarh" },
    { city: "UttarPradesh Raebareli" },
    { city: "UttarPradesh Rampur" },
    { city: "UttarPradesh Saharanpur" },
    { city: "UttarPradesh Sambhal" },
    { city: "UttarPradesh Sant Kabir Nagar" },
    { city: "UttarPradesh Shahjahanpur" },
    { city: "UttarPradesh Shamli" },
    { city: "UttarPradesh Shravasti" },
    { city: "UttarPradesh Siddharthnagar" },
    { city: "UttarPradesh Sitapur" },
    { city: "UttarPradesh Sonbhadra" },
    { city: "UttarPradesh Sultanpur" },
    { city: "UttarPradesh Unnao" },
    { city: "UttarPradesh Varanasi" },
    { city: "Uttarakhand Almora" },
    { city: "Uttarakhand Bageshwar" },
    { city: "Uttarakhand Chamoli" },
    { city: "Uttarakhand Champawat" },
    { city: "Uttarakhand Dehradun" },
    { city: "Uttarakhand Haridwar" },
    { city: "Uttarakhand Nainital" },
    { city: "Uttarakhand Pauri Garhwal" },
    { city: "Uttarakhand Pithoragarh" },
    { city: "Uttarakhand Rudraprayag" },
    { city: "Uttarakhand Tehri Garhwal" },
    { city: "Uttarakhand Udham Singh Nagar" },
    { city: "Uttarakhand Uttarkashi" },
    { city: "WestBengal Alipurduar" },
    { city: "WestBengal Bankura" },
    { city: "WestBengal Birbhum" },
    { city: "WestBengal Cooch Behar" },
    { city: "WestBengal Dakshin Dinajpur" },
    { city: "WestBengal Darjeeling" },
    { city: "WestBengal Hooghly" },
    { city: "WestBengal Howrah" },
    { city: "WestBengal Jalpaiguri" },
    { city: "WestBengal Jhargram" },
    { city: "WestBengal Kalimpong" },
    { city: "WestBengal Kolkata" },
    { city: "WestBengal Malda" },
    { city: "WestBengal Murshidabad" },
    { city: "WestBengal Nadia" },
    { city: "WestBengal North 24 Parganas" },
    { city: "WestBengal Paschim Bardhaman" },
    { city: "WestBengal Paschim Medinipur" },
    { city: "WestBengal Purba Bardhaman" },
    { city: "WestBengal Purba Medinipur" },
    { city: "WestBengal Purulia" },
    { city: "WestBengal South 24 Parganas" },
    { city: "WestBengal Uttar Dinajpur" },
    { city: "Chandigarh" },
    { city: "Delhi Central Delhi" },
    { city: "Delhi East Delhi" },
    { city: "Delhi New Delhi" },
    { city: "Delhi North Delhi" },
    { city: "Delhi North East Delhi" },
    { city: "Delhi North West Delhi" },
    { city: "Delhi Shahdara" },
    { city: "Delhi South Delhi" },
    { city: "Delhi South East Delhi" },
    { city: "Delhi South West Delhi" },
    { city: "Delhi West Delhi" },
    { city: "Puducherry Karaikal" },
    { city: "Puducherry Mahe" },
    { city: "Puducherry Puducherry" },
    { city: "Puducherry Yanam" },
  ];

  const formattedCourseOptions = indiaStatesAndDistricts.map((course) => ({
    label: course.city,
    value: course.city,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f0f0f0",
      borderColor: "white",
      height: "40px",
      width: "273px",
      boxShadow: " 2px 2px 5px gray",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#007bff"
        : state.isFocused
        ? "#e6f2ff"
        : null,
      color: state.isSelected ? "white" : "black",
      "&:active": {
        backgroundColor: "#0056b3",
        color: "white",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.25rem",
      marginTop: "0",
      boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
      fontStyle: "italic",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    input: (provided) => ({
      ...provided,
      color: "#495057",
    }),
  };

  switch (step) {
    case 0:
      return (
        <div className="steppercont">
          <div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="stepperinput"
                id="name"
                placeholder="Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="stepperinput"
                id="phone"
                placeholder="Enter Your Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                E-mail
              </label>
              <input
                type="email"
                className="stepperinput"
                id="email"
                placeholder="Enter Your E-mail Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="date" className="form-label">
                Shifting Date
              </label>
              <input
                type="date"
                className="stepperinput"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </div>
        </div>
      );

    case 1:
      return (
        <div className="steppercont">
          <div>
            <div className="mb-3">
              <label htmlFor="from" className="form-label">
                From
              </label>
              <Select
                type="text"
                styles={customStyles}
                id="from"
                placeholder="Enter starting location"
                isSearchable
                options={formattedCourseOptions}
                value={formattedCourseOptions.find(
                  (option) => option.value === from
                )}
                onChange={(selectedOption) => setFrom(selectedOption.value)}
              />
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="to" className="form-label">
                To
              </label>
              <Select
                type="text"
                styles={customStyles}
                id="from"
                placeholder="Enter Final Destination"
                isSearchable
                options={formattedCourseOptions}
                value={formattedCourseOptions.find(
                  (option) => option.value === to
                )}
                onChange={(selectedOption) => setTo(selectedOption.value)}
              />
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="service" className="form-label">
                Service
              </label>
              <select
                className="stepperinput"
                id="service"
                value={service}
                onChange={handleServiceChange}
              >
                <option value="">--service--</option>
                <option value="WAREHOUSES">WAREHOUSES</option>
                <option value="CARGO">CARGO</option>
                <option value="PET-RELOCATION">PET-RELOCATION</option>
                <option value="TRANSPORT">TRANSPORT</option>
                <option value="BIKE-TRANSPORTATION">BIKE-TRANSPORTAION</option>
                <option value="CAR-TRANSPORTATION">CAR-TRANSPORTATION</option>
                <option value="INTERNATIONAL-MOVERS">
                  INTERNATIONAL-MOVERS
                </option>
                <option value="PACKERS & MOVERS">PACKERS & MOVERS</option>
                <option value="HIRE-TRUCK-TEMPO">HIRE-TRUCK-TEMPO</option>
              </select>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label htmlFor="sub-service" className="form-label">
                Sub-Service
              </label>
              <select
                className="stepperinput"
                id="sub-service"
                value={subService}
                onChange={handleSubServiceChange}
              >
                <option value="">--sub-service--</option>
                {subServiceOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {service === "TRANSPORT" && (
            <>
              <div className="mb-3">
                <label htmlFor="sub-sub-service1" className="form-label">
                  Select Capacity
                </label>
                <select
                  className="stepperinput"
                  id="sub-sub-service1"
                  value={subSubService1}
                  onChange={(e) => setSubSubService1(e.target.value)}
                >
                  <option value="">Select Capacity</option>
                  {capacityOptionsMapping[subService]?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="sub-sub-service2" className="form-label">
                  Other Option
                </label>
                <input
                  type="text"
                  className="stepperinput"
                  id="phone"
                  placeholder="Enter Weight In Ton"
                  value={subSubService2}
                  onChange={(e) => setSubSubService2(e.target.value)}
                />
              </div>
            </>
          )}
        </div>
      );

    default:
      return "Unknown step";
  }
}

const LinaerStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [service, setService] = useState("");
  const [date, setDate] = useState("");
  const [subService, setSubService] = useState("");
  const [subSubService1, setSubSubService1] = useState("");
  const [subSubService2, setSubSubService2] = useState("");
  const [serviceOptions, setServiceOptions] = useState(initialServiceOptions);

  const submit = async () => {
    try {
      console.log("Response object:", {
        name,
        email,
        phone,
        to,
        from,
        service,
        date,
        subService, // Log subService
        subSubService1, // Log subSubService1
        subSubService2, // Log subSubService2
      });
      const res = await axios.post(
        "https://eazymov.in:8092/api/query/newquery",
        {
          name,
          email,
          phone,
          to,
          from,
          service,
          date,
          subService,
          subSubService1,
          subSubService2,
        }
      );
      console.log("Response from backend:", res.data);
      if (res?.data?.success) {
        toast.success("We will contact you as soon as possible");
        // setTimeout(function () {
        //   window.location.reload();
        // }, 5000);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      submit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <ToastContainer />
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank You
        </Typography>
      ) : (
        <>
          <form>
            {getStepContent(
              activeStep,
              name,
              setName,
              phone,
              setPhone,
              email,
              setEmail,
              from,
              setFrom,
              to,
              setTo,
              service,
              setService,
              date,
              setDate,
              subService,
              setSubService,
              serviceOptions,
              subSubService1,
              setSubSubService1,
              subSubService2,
              setSubSubService2
            )}
          </form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              margin: "10px 0",
            }}
          >
            <Button
              className={classes.button}
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default LinaerStepper;
