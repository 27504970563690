import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../pages/home.css";

const Servicepro = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const card = [
    {
      address:
        "G/F, E-1002, Block E, C R Park, South Delhi, New Delhi, Delhi, India - 110019",
      services: ["Transport", "Bike", "Car"],
      head: "Malik brothers packers and movers",
    },
    {
      address: "vpo- bharthal village dwarka sector-26",
      services: ["household", "transport", "packers & movers"],
      head: " Chetak Logistics Solutions",
    },
    {
      address:
        "B.B.D, Sarani, Behind Central Bank of India, Main Road Haidar Para, Siliguri - 734006",
      services: ["Warehousing", "Packers & Movers"],
      head: "Amar packers and movers",
    },
    {
      address:
        "Plot No 59 and 60, Neelam Bata Road, Faridabad Nit, Faridabad - 121001 (Near Union Bank Of India)",
      services: ["Cargo", "Logistics"],
      head: "DHL",
    },
    {
      address: "vpo bijwasan sector 27 najafgarh new Delhi-110043",
      services: ["Cargo", "Logistics"],
      head: "r-sai logistic solution",
    },
    {
      address: "RK tower faridabad haryana 121001",
      services: ["Cargo", "Logistics"],
      head: "Technical carrier industries       ",
    },
    {
      address: "bharthal village dwarka sector-26 new delhi - 110077",
      services: ["Cargo", "Logistics"],
      head: "Star home packers and movers",
    },
  ];

  return (
    <div style={{ overflowX: "hidden" }}>
      <h1
        className="text-center"
        style={{
          fontSize: "32px",
          fontWeight: "700",
          textShadow: "2px 2px 5px #1a1a1a",
        }}
      >
        Some of Our Service Providers
      </h1>
      <div className="container py-5">
        <div className="row">
          <Slider {...settings}>
            {card.map((e, index) => (
              <>
                <div style={{ padding: "10px" }}>
                  <div
                    style={{ border: "1px solid black", padding: "20px 10px" }}
                  >
                    <div style={{ width: "300px" }}>
                      <div
                        style={{
                          width: "20%",
                          float: "left",
                          padding: "10px 0",
                          height: "auto",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "lightgray",
                            padding: "10px",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ fontSize: "40px" }}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "80%",
                          float: "right",
                          height: "auto",
                          padding: "10px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "14px",
                            lineHeight: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {e.head}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            lineHeight: "12px",
                          }}
                        >
                          {e.address}
                        </p>
                      </div>
                    </div>
                    <div>
                      <span>Services</span>
                      <ul
                        style={{
                          listStyle: "none",
                          display: "flex",
                          justifyContent: "left",
                          gap: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {e.services.map((service, i) => (
                          <li
                            style={{
                              // backgroundColor: "lightgray",
                              padding: "0 10px",
                              borderRadius: "10px",
                              boxShadow: "2px 2px 5px lightgray",
                            }}
                            key={i}
                          >
                            {service}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button className="btn btn-success">Call Now</button>
                      <button className="btn btn-primary">
                        Get Best Quotes
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Servicepro;

{
  /* {card.map((e) => (
          <>
            <div style={{ border: "1px solid lightgray", padding: "50px 0" }}>
              <div style={{ width: "300px" }}>
                <div style={{ width: "20%", float: "left", height: "70px" }}>
                  <div
                    style={{ backgroundColor: "lightgray", padding: "10px" }}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ fontSize: "50px" }}
                    />
                  </div>
                </div>
                <div style={{ width: "80%", float: "right", height: "70px" }}>
                  {e.h}
                </div>
              </div>
              <div>
                <span>Services</span>
                <ul
                  style={{
                    listStyle: "none",
                    display: "flex",
                    justifyContent: "left",
                    gap: "10px",
                  }}
                >
                  {e.services.map((service, i) => (
                    <li
                      style={{
                        // backgroundColor: "lightgray",
                        padding: "0 10px",
                        borderRadius: "10px",
                        boxShadow: "2px 2px 5px lightgray",
                      }}
                      key={i}
                    >
                      {service}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <button className="btn btn-success">Call Now</button>
                <button className="btn btn-primary">Get Best Quotes</button>
              </div>
            </div>
          </>
        ))} */
}
