import { useEffect, useState } from "react";

import axios from "axios";
import { Outlet } from "react-router-dom";
import Spinner from "./Spinner";
import { useAuth } from "../auth/Auth";

const AlluserRoute = () => {
  const [auth] = useAuth();
  const [ok, setOk] = useState(false);

  useEffect(() => {
    const authCheck = async () => {
      try {
        const res = await axios.get(
          "https://eazymov.in:8092/api/user/dashboard-admin2",
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Add the Authorization header
            },
          }
        );
        // Check if the response status is in the range of 200 to 299, indicating success
        if (res.status >= 200 && res.status < 300) {
          setOk(true);
        } else {
          setOk(false);
        }
      } catch (error) {
        console.log(error);
        setOk(false);
      }
    };

    if (auth?.token) {
      authCheck();
    }
  }, [auth?.token]);

  return ok ? <Outlet /> : <Spinner path="" />;
};

export default AlluserRoute;
