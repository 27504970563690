import React from "react";
import Layout from "../comp/Layout";
import { Link, useNavigate } from "react-router-dom";
import bg from "../img/about-page-bg.jpg";
import serviceimg from "../img/intmovers.jpg";
import "./service.css";
import FirstComp from "../comp/slide";
import AccordionExpandIcon from "../comp/Faq";
import Steps from "../comp/Steps";
import LinaerStepper from "../comp/Counter";

const International = () => {
  const navigate = useNavigate();
  const card = [
    {
      img: "/img/single1.PNG",
      head: "Sea Delivery",
      text: "Reliable sea delivery services ensuring your cargo reaches global destinations safely and on time.",
    },
    {
      img: "/img/single2.PNG",
      head: "Packaged Goods",
      text: "Expert handling and efficient management of all your packaged goods for seamless transport.",
    },
    {
      img: "/img/single3.PNG",
      head: "Warehousing",
      text: "Secure and organized warehousing solutions tailored to keep your goods safe and accessible.",
    },
  ];
  return (
    <Layout title={"International-Movers"}>
      <div
        className="container-fluid p-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row text-light">
          <div className="col-sm-12">
            <h4 style={{ fontSize: "36px", fontWeight: "700" }}>
              INTERNATIONAL MOVERS
            </h4>
            <div
              style={{
                height: "3px",
                width: "80px",
                backgroundColor: "white",
                border: "none",
                margin: "20px 0",
              }}
            ></div>
            <p style={{ fontSize: "16px", padding: "20px 0" }}>
              You Are Here : <Link to="/">Home</Link> {">"}{" "}
              <Link to="/services">Services</Link> {">"} International Movers
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 col-sm-12">
            <img src={serviceimg} alt="img" className="singleimg" />
            <h1>International Movers</h1>
            <div
              style={{
                height: "1.8px",
                width: "80px",
                backgroundColor: "#08487E",
                border: "none",
                margin: "10px 0",
              }}
            ></div>
            <p>
              At Eazymov, our International Movers services are designed to meet
              the demands of today’s fast-paced global market. We prioritize
              speed and reliability, ensuring that your shipments reach their
              destination promptly and efficiently. Our extensive network of
              airline partners and logistics experts work together to handle
              every aspect of air transportation, from booking to delivery.
            </p>
            <p>
              We understand that each shipment is unique, which is why we offer
              flexible International Movers solutions tailored to your specific
              needs. Whether you require express delivery for urgent cargo or a
              cost-effective option for less time-sensitive shipments, Eazymov
              provides a range of International Movers services to suit every
              requirement. Our commitment to exceptional customer service
              ensures that you receive real-time updates and support throughout
              the shipping process.
            </p>
            <p>
              At Eazymov, we are dedicated to continuous improvement and
              innovation in our International Movers operations. Our goal is to
              expand our global reach while maintaining the highest standards of
              service quality. By leveraging advanced technology and industry
              best practices, we strive to enhance the efficiency and
              reliability of our International Movers services, helping you stay
              ahead in the competitive landscape.
            </p>
          </div>
          <div className="col-md-4 col-sm-12 px-5">
            <h4>Other Services</h4>
            <ul>
              <li
                onClick={() => {
                  navigate("/car-transportation");
                }}
                className="singleserlist"
              >
                Car Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/packers-movers");
                }}
                className="singleserlist"
              >
                Packers & Movers
              </li>
              <li
                onClick={() => {
                  navigate("/hire-truck-tempo");
                }}
                className="singleserlist"
              >
                Hire Truck Tempo
              </li>
              <li
                onClick={() => {
                  navigate("/transport");
                }}
                className="singleserlist"
              >
                Transport
              </li>
              <li
                onClick={() => {
                  navigate("/bike-transportation");
                }}
                className="singleserlist"
              >
                Bike Transportation
              </li>
              <li
                onClick={() => {
                  navigate("/international-movers");
                }}
                className="singleserlist"
              >
                International Movers
              </li>
              <li
                onClick={() => {
                  navigate("/pet-relocation");
                }}
                className="singleserlist"
              >
                Pet Relocation
              </li>
              <li
                onClick={() => {
                  navigate("/cargo");
                }}
                className="singleserlist"
              >
                Cargo
              </li>
              <li
                onClick={() => {
                  navigate("/warehouse");
                }}
                className="singleserlist"
              >
                Warehousing
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Steps />
      <LinaerStepper />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1
              className="text-center"
              style={{
                textShadow: "2px 2px 5px gray",
                fontWeight: "700",
                textDecoration: "underline",
              }}
            >
              International Movers Services
            </h1>
            <h2 className="htag mt-5">
              International Movers: Simplifying Global Relocations
            </h2>
            <p className="ptag">
              Moving internationally can be a complex and challenging process.
              At Eazymov, we specialize in providing seamless and stress-free
              international moving services. Our team of experienced
              professionals is dedicated to ensuring that your belongings are
              safely transported to your new destination, no matter where in the
              world it may be.
            </p>
            <h2 className="htag mt-5">
              Customized International Moving Solutions
            </h2>
            <p className="ptag">
              Every international move is unique, which is why we offer
              customized moving solutions tailored to your specific needs.
              Whether you are relocating for work, family, or personal reasons,
              Eazymov provides a comprehensive range of services including
              packing, shipping, and customs clearance, ensuring a smooth
              transition to your new home.
            </p>
            <h2 className="htag mt-5">
              Expert Handling and Secure Transportation
            </h2>
            <p className="ptag">
              Your possessions are valuable, and our expert team understands the
              importance of handling them with the utmost care. We use
              high-quality packing materials and state-of-the-art equipment to
              secure your items during transit. Our global network ensures that
              your belongings are transported efficiently and securely to any
              destination.
            </p>

            <h2 className="htag mt-5">
              Efficient Customs Clearance and Documentation
            </h2>
            <p className="ptag">
              Navigating customs regulations can be daunting, but with Eazymov,
              you don't have to worry. Our experienced team manages all aspects
              of customs clearance and documentation, ensuring compliance with
              international laws and minimizing any delays or complications.
            </p>
            <h2 className="htag mt-5">
              End-to-End Support for a Hassle-Free Experience
            </h2>
            <p className="ptag">
              From the initial planning stages to the final delivery at your new
              home, Eazymov provides end-to-end support throughout your
              international move. Our dedicated customer service team is
              available to answer any questions and address any concerns, making
              your relocation as hassle-free as possible.
            </p>
            <h2 className="htag mt-5">
              Why Choose Eazymov for Your International Move?
            </h2>
            <p className="ptag">
              Choosing Eazymov means choosing a partner committed to excellence.
              We prioritize customer satisfaction, offering transparent pricing,
              reliable services, and a personalized approach to international
              moving. Trust Eazymov to make your global relocation a success.
            </p>
          </div>
        </div>
      </div>
      <FirstComp />
      <AccordionExpandIcon />
      <div className="container-fluid py-5">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h5
              className="py-4"
              style={{
                fontSize: "36px",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
            >
              Are you ready to work with us?
            </h5>
            <button
              style={{
                fontSize: "15",
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              className="reqbtn"
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              Contact Us Now
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default International;
