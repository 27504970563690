import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const FirstComp = () => {
  const images = [
    {
      original: "/img/slider2.1.jpg",
      thumbnail: "/img/slider2.1.jpg",
    },
    {
      original: "/img/slider2.2.jpg",
      thumbnail: "/img/slider2.2.jpg",
    },
    {
      original: "/img/slider2.3.jpg",
      thumbnail: "/img/slider2.3.jpg",
    },
    {
      original: "/img/slider2.4.jpg",
      thumbnail: "/img/slider2.4.jpg",
    },
    {
      original: "/img/slider2.5.jpg",
      thumbnail: "/img/slider2.5.jpg",
    },
    {
      original: "/img/slider2.6.jpg",
      thumbnail: "/img/slider2.6.jpg",
    },
  ];
  return <>{/* <div clb */}</>;
};

export default FirstComp;
